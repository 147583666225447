import { Cms_EditorData, Cms_Link, Cms_SingleMedia, Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export type Cms_ContentTeaserBigVariant = 'image_text' | 'text_image';

export interface Cms_ContentTeaserBig extends Cms_ContentSlice {
  __component: 'content.teaser-big';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  teaser?: string;
  text?: Cms_EditorData;
  image?: Cms_SingleMedia;
  imageZoom?: boolean;
  links?: Cms_Link[];
  variant: Cms_ContentTeaserBigVariant;
}

export const populateTeaserBig = {
  populate: {
    links: populateLink,
    contentBlock: true
  }
};
