import {
  Cms_EditorData,
  Cms_Link,
  Cms_ResponsiveVideo,
  Cms_SingleMedia,
  Cms_TitleTag
} from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateResonsiveVideo, populateLink } from './populateCommon';

export interface Cms_ContentParallaxListItem {
  id: number;
  title?: string;
  titleHtmlTag?: Cms_TitleTag;
  teaser?: string;
  text?: Cms_EditorData;
  link?: Cms_Link;
}

export interface Cms_ContentParallaxList extends Cms_ContentSlice {
  __component: 'content.parallax-list';
  title?: string; // internal
  image?: Cms_SingleMedia;
  video?: Cms_ResponsiveVideo;
  items?: Cms_ContentParallaxListItem[];
}

export const populateParallaxList = {
  populate: {
    image: true,
    video: { ...populateResonsiveVideo },
    items: {
      populate: {
        link: { ...populateLink }
      }
    },
    contentBlock: true
  }
};
