import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const languageNavigationDefaultVariants = {}
const languageNavigationCompoundVariants = []

const languageNavigationSlotNames = [
  [
    "trigger",
    "language-navigation__trigger"
  ],
  [
    "headerLangSelect",
    "language-navigation__headerLangSelect"
  ],
  [
    "headerContainer",
    "language-navigation__headerContainer"
  ],
  [
    "footerLangSelect",
    "language-navigation__footerLangSelect"
  ],
  [
    "selectTrigger",
    "language-navigation__selectTrigger"
  ],
  [
    "selectTriggerIcon",
    "language-navigation__selectTriggerIcon"
  ],
  [
    "selectContentMobile",
    "language-navigation__selectContentMobile"
  ],
  [
    "selectContent",
    "language-navigation__selectContent"
  ],
  [
    "selectItem",
    "language-navigation__selectItem"
  ],
  [
    "selectItemMobile",
    "language-navigation__selectItemMobile"
  ]
]
const languageNavigationSlotFns = /* @__PURE__ */ languageNavigationSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, languageNavigationDefaultVariants, getSlotCompoundVariant(languageNavigationCompoundVariants, slotName))])

const languageNavigationFn = memo((props = {}) => {
  return Object.fromEntries(languageNavigationSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const languageNavigationVariantKeys = [
  "isOpen"
]
const getVariantProps = (variants) => ({ ...languageNavigationDefaultVariants, ...compact(variants) })

export const languageNavigation = /* @__PURE__ */ Object.assign(languageNavigationFn, {
  __recipe__: false,
  __name__: 'languageNavigation',
  raw: (props) => props,
  variantKeys: languageNavigationVariantKeys,
  variantMap: {
  "isOpen": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, languageNavigationVariantKeys)
  },
  getVariantProps
})