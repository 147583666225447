import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const mainNavigationItemDefaultVariants = {}
const mainNavigationItemCompoundVariants = []

const mainNavigationItemSlotNames = [
  [
    "navItem",
    "main-navigation-item__navItem"
  ],
  [
    "itemTrigger",
    "main-navigation-item__itemTrigger"
  ],
  [
    "label",
    "main-navigation-item__label"
  ],
  [
    "navIcon",
    "main-navigation-item__navIcon"
  ],
  [
    "line",
    "main-navigation-item__line"
  ]
]
const mainNavigationItemSlotFns = /* @__PURE__ */ mainNavigationItemSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, mainNavigationItemDefaultVariants, getSlotCompoundVariant(mainNavigationItemCompoundVariants, slotName))])

const mainNavigationItemFn = memo((props = {}) => {
  return Object.fromEntries(mainNavigationItemSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const mainNavigationItemVariantKeys = [
  "isActive"
]
const getVariantProps = (variants) => ({ ...mainNavigationItemDefaultVariants, ...compact(variants) })

export const mainNavigationItem = /* @__PURE__ */ Object.assign(mainNavigationItemFn, {
  __recipe__: false,
  __name__: 'mainNavigationItem',
  raw: (props) => props,
  variantKeys: mainNavigationItemVariantKeys,
  variantMap: {
  "isActive": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, mainNavigationItemVariantKeys)
  },
  getVariantProps
})