/**
 * https://www.meilisearch.com/docs/reference/api/overview
 */
'use server';

import { store } from '@lib/store';

import { Cms_SearchRequestParams, Cms_SearchResponse, Cms_SearchResult } from './search.types';

const indexes = ['sitemap_node', 'page', 'blog_post', 'partner', 'team_member'];

export const abortSearch = async () => {
  const client = store.getSearchClient();

  if (!client) {
    return;
  }

  client.cancelTasks({ indexUids: indexes });
};

export const searchContents = async (
  params: Cms_SearchRequestParams
): Promise<Cms_SearchResponse | null> => {
  const { q, tenant, locale, sitemap } = params;
  const client = store.getSearchClient();
  const pg = store.getPageData();

  if (!client || !pg) {
    return null; // getApiError('Search client not initialized');
  }

  //  client.cancelTasks({ indexUids: indexes });

  const response = await client.multiSearch({
    queries: indexes.map(index => ({
      offset: 0,
      limit: 1000,
      showRankingScore: true,
      indexUid: pg.config?.fullTextSearch?.meilisearchIndexPrefix
        ? `${pg.config?.fullTextSearch?.meilisearchIndexPrefix}_${index}`
        : index,
      q
      // attributesToHighlight: ['*']
    }))
  });

  const results: Cms_SearchResult[] = [];
  let totalHits = 0;

  response.results.forEach(result => {
    let hits = result.hits.filter(h => h.tenants?.includes(tenant) && h.locale === locale);

    const prefix = store.getPageData().config?.fullTextSearch?.meilisearchIndexPrefix;

    switch (result.indexUid) {
      case `${prefix}_sitemap_node`:
        hits = hits.filter(h => h.type === 'internal' && h.path);
        if (sitemap) {
          hits = hits.filter(h => sitemap.items.find(i => i.id === h.id));
        }
        break;
      case `${prefix}_page`:
        hits = hits.filter(h => h.publishedAt && h.sitemapNodes && h.sitemapNodes.length > 0);
        if (sitemap) {
          hits = hits.filter(h => {
            let isIn = false;
            h.sitemapNodes?.forEach((n: any) => {
              const match = sitemap.items.find(i => i.id === n.id);
              if (match?.active) {
                isIn = true;
              }
            });

            return isIn;
          });
        }
        break;

      case `${prefix}_patner`:
        hits = hits.filter(h => h.publishedAt);
        break;

      case `${prefix}_team_member`:
        //  hits = hits.filter(h => h.publishedAt);
        break;

      case `${prefix}_blog_post`:
        hits = hits.filter(h => h.publishedAt);

        break;

      default:
        break;
    }

    if (hits.length > 0) {
      totalHits += hits.length;

      results.push({ ...result, hits });
    }
  });

  return {
    results,
    totalHits
  };
};
