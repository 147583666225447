import { useCallback } from 'react';

import { GA_ActionKind, GA_PushPayload, GA_Target, analytics } from '@lib/services/googleAnalytics';

const isDebug = false;

export const useAnalytics = () => {
  const handleGetDataLayer = () => {
    return analytics.getDataLayer();
  };

  const handlePush = useCallback((payload: GA_PushPayload) => {
    const dl = analytics.getDataLayer();

    if (!window._env_?.gaId || !dl || !dl.push) {
      // eslint-disable-next-line no-console
      console.warn('ANALYTICS: not GA client found!');

      return;
    }

    if (process.env.APP_ENV !== 'prod') {
      // eslint-disable-next-line no-console
      if (isDebug) console.log('ANALYTICS push:', JSON.stringify(payload, null, 2));
    }
    dl.push(payload);
  }, []);

  const handleTrackEvent = useCallback(
    (target: GA_Target, action: GA_ActionKind, value?: any) => {
      handlePush({
        event: 'interaction',
        target,
        action,
        'target-properties': value
      });
    },
    [handlePush]
  );

  return {
    push: handlePush,
    trackEvent: handleTrackEvent,
    getDataLayer: handleGetDataLayer
  };
};
