import {
  Cms_DefaultAttributes,
  Cms_EditorData,
  Cms_SingleMedia,
  Cms_TitleTag
} from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

type IconType =
  | 'arrow-right'
  | 'play'
  | 'sort'
  | 'moveLeft'
  | 'moveRight'
  | 'shopping-cart'
  | 'high-five'
  | 'mail-text'
  | 'stop'
  | 'pause'
  | 'search'
  | 'linkedin'
  | 'check-circle'
  | 'angles-collapse'
  | 'angles-expand'
  | 'share'
  | 'check'
  | 'angle-down-circle'
  | 'angle-up-circle'
  | 'download'
  | 'envelope'
  | 'exclamation-circle'
  | 'triangle-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'close'
  | 'phone'
  | 'pin-location'
  | 'flag-exclamation'
  | 'factory-building'
  | 'zoom-in'
  | 'zoom-out'
  | 'expand'
  | 'cookie'
  | 'question-circle'
  | 'dashboard'
  | 'add'
  | 'cog'
  | 'analytic-board-bars'
  | 'subscription'
  | 'paper-write'
  | 'remove-circle'
  | 'house'
  | 'plus-circle'
  | 'show-hat-magician'
  | 'list-to-do'
  | 'messages-bubble-square-information'
  | 'navigation-menu-vertical'
  | 'remove-circle'
  | 'link'
  | 'user'
  | 'menu-burger';

export interface Cms_ContentDownloadFormFacts {
  title: string;
  icon?: IconType;
}

interface Cms_ContentContactFormTerms extends Cms_DefaultAttributes {
  checkboxLabel?: Cms_EditorData;
  content?: Cms_EditorData;
}

export interface Cms_ContentDownloadForm extends Cms_ContentSlice {
  __component: 'content.download-form';
  context?: string;
  title: string;
  titleHtmlTag: Cms_TitleTag;
  emailSubject?: string;
  teaser?: string;
  text?: Cms_EditorData;
  bgImage?: Cms_SingleMedia;
  formFields: string[];
  terms?: Cms_ContentContactFormTerms;
  downloadImage: Cms_SingleMedia;
  downloadTitle?: string;
  downloadTitleHtmlTag?: Cms_TitleTag;
  downloadFile: Cms_SingleMedia;
  downloadFacts?: Cms_ContentDownloadFormFacts[];
}

export const populateDownloadForm = {
  populate: {
    downloadImage: true,
    downloadFile: true,
    contentBlock: true,
    downloadFacts: true
  }
};
