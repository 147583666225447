import { useEffect, useState } from 'react';

import { useWindowScroll } from 'react-use';

export const useHeaderBanner = (hasHeaderBanner?: boolean) => {
  const { y } = useWindowScroll();
  const [isBannerActive, setBannerActive] = useState(false);

  useEffect(() => {
    if (!hasHeaderBanner) {
      setBannerActive(false);

      return;
    }

    setBannerActive(y < 40);
  }, [hasHeaderBanner, y]);

  return isBannerActive;
};
