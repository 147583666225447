export const populateLink = {
  populate: {
    blogPost: true,
    download: true
  }
};

export const populateMeta = {
  populate: {
    contentBlock: true,
    ogImage: true,
    schemas: true
  }
};

export const populateResonsiveVideo = {
  populate: {
    videoSm: true,
    videoMd: true,
    videoLg: true
  }
};
