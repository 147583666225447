/**
 * - https://ui.shadcn.com/docs/components/carousel
 * - https://www.embla-carousel.com/get-started/react/
 */
'use client';

import * as React from 'react';

import { css, cx } from '@pt-frontends/styled-system/css';
import type { HstackProps } from '@pt-frontends/styled-system/jsx';
import { Center, HStack } from '@pt-frontends/styled-system/jsx';
import { srOnly } from '@pt-frontends/styled-system/patterns';
import { carousel } from '@pt-frontends/styled-system/recipes';
import type { EmblaCarouselType } from 'embla-carousel';
import type { UseEmblaCarouselType } from 'embla-carousel-react';
import useEmblaCarousel from 'embla-carousel-react';
import { omit } from 'lodash';
import { useMeasure } from 'react-use';
import type { UseMeasureRect } from 'react-use/lib/useMeasure';

import { arrayRange } from '@lib/utils';

import { ButtonProps } from '@ui/button';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  animation?: 'slide' | 'fade';
  setApi?: (api: CarouselApi) => void;
  onIndexChange?: (index: number) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  scrollTo: (index: number) => void;
  setMaxHeight: (height: number) => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  selectedIndex: number;
  totalSlides: number;
  measure: UseMeasureRect & { maxHeight: number };
  //  scrollSnaps: number[];
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

// const navBtn = css.raw({
//   cursor: 'pointer',
//   h: 8,
//   w: 8,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   transition: 'colors',
//   rounded: 'full'
// });

// const navBtnIcon = css.raw({
//   h: 4,
//   w: 4
// });

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      onIndexChange,
      plugins,
      className,

      children,
      animation = 'slide',
      ...props
    },
    ref
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y'
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);
    const [maxHeight, setMaxHeight] = React.useState(0);

    const onSelect = React.useCallback((cApi: CarouselApi) => {
      if (!cApi) {
        return;
      }

      setCanScrollPrev(cApi.canScrollPrev());
      setCanScrollNext(cApi.canScrollNext());
      setSelectedIndex(cApi.selectedScrollSnap());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const scrollTo = React.useCallback((index: number) => api?.scrollTo(index), [api]);

    const onInit = React.useCallback((capi: EmblaCarouselType) => {
      setScrollSnaps(capi.scrollSnapList());
    }, []);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onInit(api as any);
      onSelect(api);
      api.on('reInit', onInit as any);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      return () => {
        api?.off('select', onSelect);
      };
    }, [api, onInit, onSelect]);

    React.useEffect(() => {
      if (onIndexChange) {
        onIndexChange(selectedIndex);
      }
    }, [onIndexChange, selectedIndex]);
    const [measureRef, measure] = useMeasure<HTMLDivElement>();

    const handleSetMaxHeight = React.useCallback(
      (height: number) => {
        if (height > maxHeight) {
          setMaxHeight(height);
        }
      },
      [maxHeight]
    );

    const content = animation === 'fade' ? <div ref={measureRef}>{children}</div> : children;
    const { root } = carousel({ orientation, animation });

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          scrollTo,
          canScrollPrev,
          canScrollNext,
          selectedIndex,
          totalSlides: scrollSnaps.length,
          animation,
          measure: { ...measure, maxHeight },
          setMaxHeight: handleSetMaxHeight
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cx(root, className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {content}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { carouselRef, orientation, animation, measure } = useCarousel();
    const { content, contentInner } = carousel({ orientation, animation });

    return (
      <div ref={carouselRef} className={cx(content, className)}>
        <div
          ref={ref}
          className={contentInner}
          style={{
            height: animation === 'fade' && measure.maxHeight ? measure.maxHeight : undefined
          }}
          {...props}
        />
      </div>
    );
  }
);
CarouselContent.displayName = 'CarouselContent';

const slideMeasure = css({
  visibility: 'hidden',
  pos: 'fixed',
  top: '-10000px',
  left: '-10000px',
  zIndex: -1
});

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { index?: number }
>(({ className, index, children, ...props }, ref) => {
  const { orientation, animation, selectedIndex, measure, setMaxHeight } = useCarousel();
  const [measureRef, dimensions] = useMeasure<HTMLDivElement>();
  const { item } = carousel({ orientation, animation });

  React.useEffect(() => {
    if (animation !== 'fade' || dimensions.width !== measure.width) return;

    setMaxHeight(dimensions.height);
  }, [animation, dimensions.height, dimensions.width, measure.width, setMaxHeight]);

  if (animation === 'fade') {
    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        data-active={selectedIndex === index}
        className={cx(item, className)}
        {...props}
      >
        {children}

        <div className={slideMeasure} style={{ width: measure.width }}>
          <div ref={measureRef} className={cx(item, className)} {...props}>
            {children}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      data-active={selectedIndex === index}
      className={cx(item, className)}
      {...props}
    >
      {children}
    </div>
  );
});
CarouselItem.displayName = 'CarouselItem';

interface CarouselPaginationProps extends HstackProps {
  buttonProps?: ButtonProps;
}

const CarouselPagination = React.forwardRef<HTMLDivElement, CarouselPaginationProps>(
  (props, ref) => {
    const { className, buttonProps } = props;
    const { selectedIndex, totalSlides, scrollTo } = useCarousel();
    const { pagination, paginationBtn, paginationDot } = carousel();

    if (totalSlides < 2) {
      return null;
    }

    return (
      <Center w="full" className={cx(pagination, className)}>
        <HStack ref={ref} gap={1} ml={-1}>
          {arrayRange(0, totalSlides - 1).map((index: number) => (
            <button
              type="button"
              className={cx(paginationBtn, buttonProps?.className, 'group')}
              key={index}
              onClick={() => scrollTo(index)}
              {...omit(buttonProps, 'className')}
            >
              <span className={paginationDot} data-active={selectedIndex === index} />
              <span className={srOnly()}>
                Select slide
                {index}
              </span>
            </button>
          ))}
        </HStack>
      </Center>
    );
  }
);
CarouselPagination.displayName = 'CarouselPagination';

/* const CarouselBtn = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & { dir: 'prev' | 'next' }
>(({ className, cl, dir, ...props }, ref) => {
  return (
    <Button ref={ref} className={clsx([className, css(navBtn, cl)])} {...props}>
      <Icon i={dir === 'prev' ? 'direction-left' : 'direction-right'} cl={navBtnIcon} />
      <span className={css({ srOnly: true })}>{dir === 'prev' ? 'Prev' : 'Next'} slide</span>
    </Button>
  );
});
CarouselBtn.displayName = 'CarouselBtn'; */

/* const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, cl, ...props }, ref) => {
  const { scrollPrev, canScrollPrev } = useCarousel();

  return (
    <CarouselBtn
      ref={ref}
      className={clsx([className, css(css.raw({ pos: 'absolute', bottom: -9, left: 0 }, cl))])}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      dir={'prev' as any}
      {...props}
    />
  );
});
CarouselPrevious.displayName = 'CarouselPrevious'; */

/* const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, cl, ...props }, ref) => {
  const { scrollNext, canScrollNext } = useCarousel();

  return (
    <CarouselBtn
      ref={ref}
      className={clsx([className, css(css.raw({ pos: 'absolute', bottom: -9, right: 0 }, cl))])}
      disabled={!canScrollNext}
      onClick={scrollNext}
      dir={'next' as any}
      {...props}
    />
  );
});
CarouselNext.displayName = 'CarouselNext'; */

export {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPagination,
  /* CarouselPrevious,
  CarouselNext,
  CarouselBtn */
  type CarouselApi
};
