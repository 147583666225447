import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const flyoutMenuStDefaultVariants = {}
const flyoutMenuStCompoundVariants = []

const flyoutMenuStSlotNames = [
  [
    "navContent",
    "flyout-menu__navContent"
  ],
  [
    "navContentInner",
    "flyout-menu__navContentInner"
  ],
  [
    "teaserBox",
    "flyout-menu__teaserBox"
  ],
  [
    "infoTitle",
    "flyout-menu__infoTitle"
  ],
  [
    "infoText",
    "flyout-menu__infoText"
  ],
  [
    "menuContainer",
    "flyout-menu__menuContainer"
  ],
  [
    "navRubricLink",
    "flyout-menu__navRubricLink"
  ],
  [
    "navLink",
    "flyout-menu__navLink"
  ],
  [
    "menuList",
    "flyout-menu__menuList"
  ]
]
const flyoutMenuStSlotFns = /* @__PURE__ */ flyoutMenuStSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, flyoutMenuStDefaultVariants, getSlotCompoundVariant(flyoutMenuStCompoundVariants, slotName))])

const flyoutMenuStFn = memo((props = {}) => {
  return Object.fromEntries(flyoutMenuStSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const flyoutMenuStVariantKeys = [
  "active"
]
const getVariantProps = (variants) => ({ ...flyoutMenuStDefaultVariants, ...compact(variants) })

export const flyoutMenuSt = /* @__PURE__ */ Object.assign(flyoutMenuStFn, {
  __recipe__: false,
  __name__: 'flyoutMenuSt',
  raw: (props) => props,
  variantKeys: flyoutMenuStVariantKeys,
  variantMap: {
  "active": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, flyoutMenuStVariantKeys)
  },
  getVariantProps
})