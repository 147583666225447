import { Cms_Link, Cms_SitemapTreeItem } from '@lib/services/cms';
import { routes } from '@lib/services/routes';

export const isLinkScroll = (link: Cms_Link, path: string): boolean => {
  if (!link._url) return true;

  return path !== routes.addTrailingSlash(link._url);
};

export const getNavItemLinkUrl = (item: Cms_SitemapTreeItem): string | null => {
  if (item.type === 'internal') {
    return item.path || null;
  }

  if (item.type === 'external' || item.type === 'phone' || item.type === 'email') {
    if (!item.url) return null;

    if (item.type === 'phone') return `tel:${item.url}`;
    if (item.type === 'email') return `mailto:${item.url}`;

    return item.url;
  }

  return null;
};
