/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * https://ui.shadcn.com/docs/components/scroll-area
 *
 * radix: https://www.radix-ui.com/primitives/docs/components/scroll-area
 */
'use client';

import * as React from 'react';
import { useRef } from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { scrollArea } from '@pt-frontends/styled-system/recipes';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    forceScrollbarMount?: boolean;
    viewportCl?: string;
    scrollbarCl?: string;
    orientation?: 'vertical' | 'horizontal';
    thumbCl?: string;
    mouseWheel?: boolean;
  }
>(
  (
    {
      className,
      children,
      viewportCl,
      orientation = 'vertical',
      scrollbarCl,
      thumbCl,
      forceScrollbarMount,
      mouseWheel,
      ...rest
    },
    ref
  ) => {
    const viewportRef = useRef<HTMLDivElement | null>(null);
    const [pos, setPos] = React.useState({ left: 0, top: 0, x: 0, y: 0 });
    const { root, viewport } = scrollArea({ orientation });

    const mouseUpHandler = () => {
      if (!viewportRef.current) {
        return;
      }

      viewportRef.current.style.cursor = 'grab';
      viewportRef.current.style.removeProperty('user-select');

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e: any) => {
      if (!viewportRef.current) {
        return;
      }

      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      viewportRef.current.scrollTop = pos.top - dy;
      viewportRef.current.scrollLeft = pos.left - dx;
    };

    const mouseDownHandler = (e: any) => {
      if (!viewportRef.current) {
        return;
      }

      viewportRef.current.style.cursor = 'grabbing';
      viewportRef.current.style.userSelect = 'none';

      const p = {
        left: viewportRef.current.scrollLeft,
        top: viewportRef.current.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY
      };

      setPos(p);

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    // TODO ADD MOUSE HANALDER BETTER....
    // https://phuoc.ng/collection/html-dom/drag-to-scroll/

    /* React.useEffect(() => {
      if (!viewportRef.current) {
        return;
      }
      viewportRef.current.addEventListener('mousedown', mouseDownHandler);
    }, [mouseDownHandler]); */

    // const handleWindowWheel = useCallback(
    //   (e: any) => {
    //     if (!mouseWheel) return;

    //     const tbl = e.target?.closest('table');

    //     if (tbl) {
    //       e.preventDefault();
    //     }
    //   },
    //   [mouseWheel]
    // );

    // React.useEffect(() => {
    //   window.addEventListener('wheel', handleWindowWheel, { passive: false });

    //   return () => {
    //     window.removeEventListener('wheel', handleWindowWheel);
    //   };
    // }, [handleWindowWheel]);

    // const handleMouseWheel = useCallback(
    //   (e: React.WheelEvent<HTMLDivElement>) => {
    //     // Ignore this event unless it's a strictly vertical wheel event (horizontal wheel events are already handled by the library)
    //     if (!viewportRef.current || e.deltaY === 0 || e.deltaX !== 0 || !mouseWheel) {
    //       return;
    //     }

    //     e.preventDefault();

    //     // Capture up/down wheel events and scroll the viewport horizontally
    //     const delta = e.deltaY;
    //     const currPos = viewportRef.current.scrollLeft;
    //     const scrollWidth = viewportRef.current.scrollWidth;

    //     const newPos = Math.max(0, Math.min(scrollWidth, currPos + delta));

    //     viewportRef.current.scrollLeft = newPos;
    //   },
    //   [mouseWheel]
    // );

    return (
      <ScrollAreaPrimitive.Root
        ref={ref}
        className={cx(root, className)}
        //   onWheel={handleMouseWheel}
        {...rest}
      >
        <ScrollAreaPrimitive.Viewport ref={viewportRef} className={cx(viewport, viewportCl)}>
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar
          forceMount={forceScrollbarMount || undefined}
          className={scrollbarCl}
          orientation={orientation}
          thumbCl={thumbCl}
        />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  }
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> & {
    thumbCl?: string;
  }
>(({ className, orientation = 'vertical', thumbCl, ...props }, ref) => {
  const { scrollbar, thumb } = scrollArea({ orientation });

  return (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cx(scrollbar, className)}
      {...props}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb className={cx(thumb, thumbCl)} />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  );
});
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
