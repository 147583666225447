import { Cms_SingleMedia, Cms_TitleTag } from '../../../types';
import { Cms_TeamMember } from '../../teamMembers';
import { Cms_ContentSlice } from '../pages.types';

export type Cms_ContentTeamLayout = 'DEFAULT' | 'TIGHTER';

export interface Cms_ContentTeam extends Cms_ContentSlice {
  __component: 'content.team';
  title?: string;
  titleHtmlTag: Cms_TitleTag;
  filterTags?: string[];
  fallbackImage?: Cms_SingleMedia;
  customSelection?: {
    data?: Cms_TeamMember[];
  };
  layout?: Cms_ContentTeamLayout;
}

export const populateTeam = {
  populate: {
    customSelection: {
      populate: {
        image: true
      }
    },
    fallbackImage: true,
    contentBlock: true
  }
};
