'use client';

import React from 'react';

import { css } from '@pt-frontends/styled-system/css';
import { useMeasure } from 'react-use';

interface CssVarDimensionProps {
  varName?: string;
  measure?: 'width' | 'height';
}

const CssVarDimension: React.FC<CssVarDimensionProps> = ({ varName, measure = 'width' }) => {
  const [ref, measures] = useMeasure<HTMLDivElement>();

  return (
    <>
      <div ref={ref} className={css({ inset: 0, pos: 'absolute' })} />
      <style>{`
        * {
          --${varName}: ${measures[measure]}px;
        }
      `}</style>
    </>
  );
};

export { CssVarDimension };
export type { CssVarDimensionProps };
