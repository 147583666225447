/**
 * https://www.radix-ui.com/primitives/docs/@/components/checkbox
 * https://ui.shadcn.com/docs/components/checkbox
 */

'use client';

import React, { useEffect, useRef, useState } from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { checkbox, checkboxGroup } from '@pt-frontends/styled-system/recipes';
import * as CheckboxRx from '@radix-ui/react-checkbox';
import { isEqual, uniqueId } from 'lodash';

import { useFormField } from '@ui/form';
import { Icon } from '@ui/icon';
import { Label } from '@ui/label';

interface CheckboxRef extends React.ElementRef<typeof CheckboxRx.Root> {}

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxRx.Root> {
  value?: string;
}

const Checkbox = React.forwardRef<CheckboxRef, CheckboxProps>((props, ref) => {
  const { children, id, className, onCheckedChange } = props;
  const { current: intId } = useRef(id || uniqueId('checkbox-'));
  const { root, inidicator, box, label, check } = checkbox();

  return (
    <CheckboxRx.Root
      {...props}
      id={intId}
      className={cx('group', root, className)}
      onCheckedChange={onCheckedChange}
      ref={ref}
    >
      <CheckboxRx.Indicator forceMount className={inidicator}>
        <span className={box}>
          <Icon size="small" i="check" className={check} />
        </span>
      </CheckboxRx.Indicator>
      <CheckboxLabel className={label} htmlFor={intId}>
        {children}
      </CheckboxLabel>
    </CheckboxRx.Root>
  );
});
Checkbox.displayName = 'Checkbox';

interface CheckboxGroupItemProps extends Omit<CheckboxProps, 'children'> {
  label: string;
  value: string;
}

type CheckboxGroupLayout = 'horizontal' | 'vertical';

interface CheckboxGroupProps {
  className?: string;
  id?: string;
  items: CheckboxGroupItemProps[];
  layout?: CheckboxGroupLayout;
  values?: string[];
  defaultValues?: string[];
  onChange?: (values: string[]) => void;
}

const CheckboxLabel = React.forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
  const { label } = checkbox();

  return <label ref={ref} className={cx(label, className)} {...props} />;
});
CheckboxLabel.displayName = 'CheckboxLabel';

const CheckboxFormLabel = React.forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
  const { error } = useFormField();
  const { label } = checkbox({ error: error !== undefined });

  return <label ref={ref} className={cx(label, className)} {...props} />;
});
CheckboxFormLabel.displayName = 'CheckboxFormLabel';

const CheckboxGroup = React.forwardRef<HTMLDivElement, CheckboxGroupProps>((props, ref) => {
  const { className, layout = 'vertical', items, values, defaultValues, onChange } = props;
  const [vals, setVals] = useState<string[]>(values || defaultValues || []);

  const handleCheckedChange = (checked: boolean | string, value: string) => {
    let newVals = [...vals];
    if (typeof checked === 'boolean') {
      if (checked && !newVals.includes(value)) {
        newVals = [...vals, value];
      } else {
        newVals = vals.filter(val => val !== value);
      }
    }

    if (onChange) onChange(newVals);
    setVals(newVals);
  };

  useEffect(() => {
    if (!values) return;
    if (isEqual(values, vals)) return;

    setVals(values);
  }, [vals, values]);

  if (items.length === 0) return null;

  return (
    <div className={cx(checkboxGroup({ layout }), className)} ref={ref}>
      {items.map(item => (
        <Checkbox
          {...item}
          key={item.value}
          checked={vals.includes(item.value)}
          onCheckedChange={checked => handleCheckedChange(checked, item.value)}
        >
          {item.label}
        </Checkbox>
      ))}
    </div>
  );
});
CheckboxGroup.displayName = 'CheckboxGroup';

export { Checkbox, CheckboxGroup, CheckboxLabel, CheckboxFormLabel };
export type { CheckboxProps, CheckboxGroupProps, CheckboxGroupItemProps, CheckboxGroupLayout };
