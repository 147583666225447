import {
  Cms_DefaultAttributes,
  Cms_EditorData,
  Cms_SingleMedia,
  Cms_TitleTag
} from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

export interface Cms_ContentContactFormAddress extends Cms_DefaultAttributes {
  address: Cms_EditorData;
  phone: string;
  email: string;
  image: Cms_SingleMedia;
}

export interface Cms_ContentContactFormTerms extends Cms_DefaultAttributes {
  checkboxLabel?: Cms_EditorData;
  content?: Cms_EditorData;
}

export type Cms_ContentContactFormFormFields =
  | 'email'
  | 'name'
  | 'country'
  | 'topic'
  | 'message'
  | 'company'
  | 'industry'
  | 'phone';

export interface Cms_ContentContactForm extends Cms_ContentSlice {
  __component: 'content.contact-form';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  teaser?: string;
  text?: Cms_EditorData;
  address?: Cms_ContentContactFormAddress;
  bgImage?: Cms_SingleMedia;
  context?: string;
  emailSubject?: string;
  formFields?: string[];
  terms?: Cms_ContentContactFormTerms;
}

export const populateContactForm = {
  populate: {
    bgImage: true,
    contentBlock: true,
    terms: true,
    address: {
      populate: {
        image: true
      }
    }
  }
};
