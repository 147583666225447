import { Cms_EditorData } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

export interface Cms_ContentText extends Cms_ContentSlice {
  __component: 'content.text';
  text?: Cms_EditorData;
}

export const populateText = {
  populate: {
    contentBlock: true
  }
};
