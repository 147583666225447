import { useMediaQuery } from 'react-responsive';

import { BREAK_POINTS } from '@lib/config';

export const useIsMobile = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAK_POINTS.lg - 1}px)`
  });

  return isMobile;
};
