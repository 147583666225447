'use client';

import { useCallback } from 'react';

import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter } from 'next/navigation';

import { routes } from '@lib/services/routes';
import type {
  PathParamsOptions,
  SearchParamsOptions
} from '@lib/services/routes/types/routes.types';
import { PageConfig } from '@lib/types';

import { useLanguage } from './useLanguage';

interface GotoOptions {
  options?: NavigateOptions;
  query?: SearchParamsOptions;
  path?: PathParamsOptions;
}

export const useGoto = () => {
  const lng = useLanguage();
  const { push } = useRouter();

  const handleNavigate = useCallback(
    (config: PageConfig, options?: GotoOptions) => {
      const path = routes.replacePathParams(config.path, { ...options?.path, lng });
      const srch = routes.getUrlSearchParams(options?.query);

      push(`${path}${srch}`, options?.options);
    },
    [lng, push]
  );

  return handleNavigate;
};
