import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const footerDefaultVariants = {}
const footerCompoundVariants = []

const footerSlotNames = [
  [
    "topBox",
    "footer__topBox"
  ],
  [
    "contactText",
    "footer__contactText"
  ],
  [
    "contactBtns",
    "footer__contactBtns"
  ],
  [
    "navBox",
    "footer__navBox"
  ],
  [
    "footerNav",
    "footer__footerNav"
  ],
  [
    "bottomBox",
    "footer__bottomBox"
  ],
  [
    "copyRight",
    "footer__copyRight"
  ],
  [
    "bottomNav",
    "footer__bottomNav"
  ],
  [
    "bottomNavItem",
    "footer__bottomNavItem"
  ],
  [
    "navItemGroupTitle",
    "footer__navItemGroupTitle"
  ],
  [
    "navItemListItem",
    "footer__navItemListItem"
  ],
  [
    "footerNavUl",
    "footer__footerNavUl"
  ],
  [
    "bottomNavUl",
    "footer__bottomNavUl"
  ]
]
const footerSlotFns = /* @__PURE__ */ footerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, footerDefaultVariants, getSlotCompoundVariant(footerCompoundVariants, slotName))])

const footerFn = memo((props = {}) => {
  return Object.fromEntries(footerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const footerVariantKeys = [
  "isFooterHidden",
  "isActive",
  "isLang",
  "isLangInacitve",
  "isLink"
]
const getVariantProps = (variants) => ({ ...footerDefaultVariants, ...compact(variants) })

export const footer = /* @__PURE__ */ Object.assign(footerFn, {
  __recipe__: false,
  __name__: 'footer',
  raw: (props) => props,
  variantKeys: footerVariantKeys,
  variantMap: {
  "isFooterHidden": [
    "true"
  ],
  "isActive": [
    "true"
  ],
  "isLang": [
    "true"
  ],
  "isLangInacitve": [
    "true"
  ],
  "isLink": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, footerVariantKeys)
  },
  getVariantProps
})