import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentHeaderFullscreenOverlayDefaultVariants = {}
const cmsContentHeaderFullscreenOverlayCompoundVariants = [
  {
    "hasHeaderBanner": false,
    "isHeaderHidden": false,
    "css": {
      "container": {
        "minH": "calc(100dvh - 92px)"
      }
    }
  },
  {
    "hasHeaderBanner": true,
    "isHeaderHidden": true,
    "css": {
      "container": {
        "minH": "calc(100dvh - 40px)"
      }
    }
  },
  {
    "hasHeaderBanner": false,
    "isHeaderHidden": true,
    "css": {
      "container": {
        "minH": "10dvh"
      }
    }
  },
  {
    "hasHeaderBanner": true,
    "isHeaderHidden": false,
    "css": {
      "container": {
        "minH": "calc(100dvh - 40px - 92px)"
      }
    }
  }
]

const cmsContentHeaderFullscreenOverlaySlotNames = [
  [
    "container",
    "cms-header-fullscreen-overlay__container"
  ],
  [
    "contentBox",
    "cms-header-fullscreen-overlay__contentBox"
  ],
  [
    "overlay",
    "cms-header-fullscreen-overlay__overlay"
  ],
  [
    "contentWrap",
    "cms-header-fullscreen-overlay__contentWrap"
  ],
  [
    "textBox",
    "cms-header-fullscreen-overlay__textBox"
  ]
]
const cmsContentHeaderFullscreenOverlaySlotFns = /* @__PURE__ */ cmsContentHeaderFullscreenOverlaySlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentHeaderFullscreenOverlayDefaultVariants, getSlotCompoundVariant(cmsContentHeaderFullscreenOverlayCompoundVariants, slotName))])

const cmsContentHeaderFullscreenOverlayFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentHeaderFullscreenOverlaySlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentHeaderFullscreenOverlayVariantKeys = [
  "hasHeaderBanner",
  "isHeaderHidden"
]
const getVariantProps = (variants) => ({ ...cmsContentHeaderFullscreenOverlayDefaultVariants, ...compact(variants) })

export const cmsContentHeaderFullscreenOverlay = /* @__PURE__ */ Object.assign(cmsContentHeaderFullscreenOverlayFn, {
  __recipe__: false,
  __name__: 'cmsContentHeaderFullscreenOverlay',
  raw: (props) => props,
  variantKeys: cmsContentHeaderFullscreenOverlayVariantKeys,
  variantMap: {
  "hasHeaderBanner": [
    "true"
  ],
  "isHeaderHidden": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentHeaderFullscreenOverlayVariantKeys)
  },
  getVariantProps
})