'use client';

import React, { useEffect } from 'react';

import { css, cx } from '@pt-frontends/styled-system/css';
import {
  footer,
  languageNavigation,
  mainNavigationItem,
  mobileNavigation
} from '@pt-frontends/styled-system-web/recipes';
import { Icon } from '@ui/icon';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/select';
import { usePathname, useRouter } from 'next/navigation';

import { LANGUAGES_TRANS } from '@lib/config';
import { Cms_SitemapAlternateLang } from '@lib/services/cms';
import { Locale } from '@lib/services/i18n';

interface LanguageNavigationProps {
  position: 'footer' | 'header' | 'mobile';
  languages: Locale[];
  current: Locale;
  fallback: Locale;
  alternateLangs?: Cms_SitemapAlternateLang[];
}

const LanguageNavigation: React.FC<LanguageNavigationProps> = ({
  languages,
  fallback,
  current,
  alternateLangs,
  position
}) => {
  const { push } = useRouter();
  const [isOpen, setOpen] = React.useState(false);
  const pathname = usePathname();

  const {
    selectContent,
    selectContentMobile,
    headerContainer,
    headerLangSelect,
    trigger,
    selectTrigger,
    selectTriggerIcon,
    selectItemMobile,
    selectItem
  } = languageNavigation({
    isOpen
  });

  const handleSelect = (lng: Locale) => {
    if (lng === current) return;

    const alt = alternateLangs?.find(lang => lang.locale === lng);

    if (!alt || !alt.path) {
      // eslint-disable-next-line no-console
      console.warn('No alternate language content found for', lng);
      push(`/${lng}`);
    }

    if (!alt) return;

    push(alt.path);
  };

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  if (languages.length === 0) {
    return null;
  }

  if (position === 'mobile') {
    if (languages.length < 2) {
      return null;
    }

    return (
      <div
        className={css({
          ml: -1.5,
          w: 'screen',
          mb: -1.5
        })}
      >
        <Select value={current} onValueChange={handleSelect} open={isOpen} onOpenChange={setOpen}>
          <SelectTrigger
            noIcon
            className={cx('group', mainNavigationItem().itemTrigger, selectTrigger)}
          >
            <SelectValue />
            <Icon
              i="angle-down-circle"
              size="small"
              className={cx(mainNavigationItem().navIcon, selectTriggerIcon)}
            />
          </SelectTrigger>
          <SelectContent className={selectContentMobile} align="start">
            {languages.map(lang => (
              <SelectItem
                key={lang}
                value={lang}
                className={cx(mobileNavigation().navItemLabel, selectItemMobile)}
              >
                {LANGUAGES_TRANS[lang] || lang}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  }

  if (position === 'header') {
    if (languages.length < 2) {
      return null;
    }

    return (
      <div className={headerContainer}>
        <Select value={current} onValueChange={handleSelect} open={isOpen} onOpenChange={setOpen}>
          <SelectTrigger
            noIcon
            className={cx('group', mainNavigationItem().itemTrigger, headerLangSelect)}
          >
            {LANGUAGES_TRANS[current]}
            <Icon i="angle-down-circle" size="small" className={mainNavigationItem().navIcon} />
          </SelectTrigger>
          <SelectContent className={selectContent} align="end" sideOffset={10}>
            {languages.map(lang => (
              <SelectItem key={lang} value={lang} className={selectItem}>
                {LANGUAGES_TRANS[lang] || lang}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  }

  if (languages.length === 1) {
    const key = languages[0] || fallback;

    return LANGUAGES_TRANS[key] || null;
  }

  return (
    <Select value={current} open={isOpen} onOpenChange={setOpen} onValueChange={handleSelect}>
      <SelectTrigger className={cx(footer({ isLang: true }).bottomNavItem, trigger)} noIcon>
        {LANGUAGES_TRANS[current]}
      </SelectTrigger>
      <SelectContent className={selectContent}>
        {languages.map(lang => (
          <SelectItem key={lang} value={lang} className={selectItem}>
            {LANGUAGES_TRANS[lang] || lang}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export { LanguageNavigation };
export type { LanguageNavigationProps };
