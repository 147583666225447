'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CmsDynamicGridItem } from '@ui/layout';
import { isEqual } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useWindowSize } from 'react-use';

import { BREAK_POINTS } from '@lib/config';
import type { Cms_ContentPricing, Cms_GridVariant } from '@lib/services/cms';

import PriceItem from './PriceItem';

interface Props {
  data: Cms_ContentPricing;
  variant: Cms_GridVariant;
}

export interface PriceItemHeight {
  index: number;
  titleHeight: number;
  servicesHeight: number;
  titleMinHeight: number;
  servicesMinHeight: number;
}

type PriceRowKind = 'title' | 'services';
export type SetPriceItemPropsHandler = (index: number, kind: PriceRowKind, value: number) => void;

const PricingList: React.FC<Props> = ({ data, variant }) => {
  const defaults: PriceItemHeight[] = useMemo(
    () =>
      data.pricePlans
        ? data.pricePlans.map((_item, index) => ({
            index,
            titleHeight: 0,
            servicesHeight: 0,
            titleMinHeight: 0,
            servicesMinHeight: 0
          }))
        : [],
    [data.pricePlans]
  );

  const [items, setItems] = useState<PriceItemHeight[]>(defaults);

  const { width } = useWindowSize();
  const [initW, setInitW] = useState(width);
  const isTablet = useMediaQuery({
    query: `(min-width: ${BREAK_POINTS.md}px)`
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${BREAK_POINTS.xl}px)`
  });

  const handleSetItemProps: SetPriceItemPropsHandler = (index, kind, value) => {
    setItems(prev => {
      const newItems: PriceItemHeight[] = [];
      prev.forEach((item, i) => {
        if (i !== index) {
          newItems.push(item);
        } else {
          if (kind === 'title') {
            newItems.push({ ...item, titleHeight: value });
          } else if (kind === 'services') {
            newItems.push({ ...item, servicesHeight: value });
          } else {
            newItems.push(item);
          }
        }
      });

      return newItems;
    });
  };

  const handleSetCalculateHeights = useCallback(() => {
    if (!isTablet || data.variant === 'cols_1') return;
    let cols = 0;

    // tablet
    if (isTablet) {
      switch (data.variant) {
        case 'cols_2':
          cols = 2;
          break;
        case 'cols_3':
          cols = 2;
          break;
        case 'cols_4':
          cols = 2;
          break;
        default:
          cols = 0;
      }
    }

    // desktop
    if (isDesktop) {
      switch (data.variant) {
        case 'cols_2':
          cols = 2;
          break;
        case 'cols_3':
          cols = 3;
          break;
        case 'cols_4':
          cols = 4;
          break;
        default:
          cols = 0;
      }
    }

    // calculate min heights
    let minHTitle: number[] = [0];
    let minHServices: number[] = [0];
    let row = 0;

    items.forEach((item, index) => {
      if (index >= cols) {
        row += 1;
        minHTitle.push(0);
        minHServices;
      }

      const mintTitleRow = minHTitle[row];
      if (mintTitleRow !== undefined && item.titleHeight > mintTitleRow) {
        minHTitle[row] = item.titleHeight;
      }

      const minServicesRow = minHServices[row];
      if (minServicesRow !== undefined && item.servicesHeight > minServicesRow) {
        minHServices[row] = item.servicesHeight;
      }
    });

    // set min heights
    row = 0;
    const newItems: PriceItemHeight[] = [];
    items.forEach((item, index) => {
      if (index > cols) {
        row += 1;
      }
      newItems.push({
        ...item,
        titleMinHeight: minHTitle[row] || 0,
        servicesMinHeight: minHServices[row] || 0
      });
    });

    if (!isEqual(newItems, items)) {
      setItems(newItems);
    }
  }, [data.variant, isDesktop, isTablet, items]);

  /**
   * calculate heights
   */
  useEffect(() => {
    if (!isTablet || data.variant === 'cols_1') return;

    handleSetCalculateHeights();
  }, [items, isTablet, data.variant, isDesktop, handleSetCalculateHeights]);

  /**
   * reset heights on mobile and if only one column
   */
  useEffect(() => {
    if (!isTablet || data.variant === 'cols_1') {
      setItems(prev => {
        const newItems: PriceItemHeight[] = [];
        prev.forEach(item => {
          newItems.push({ ...item, titleHeight: 0, servicesHeight: 0 });
        });

        return newItems;
      });
    }
  }, [isTablet, data.variant]);

  /**
   * reset heights on window resize
   */
  useEffect(() => {
    if (initW !== width) {
      setItems(defaults);
      setInitW(width);
      handleSetCalculateHeights();
    }
  }, [defaults, handleSetCalculateHeights, initW, setInitW, width]);

  if (!data.pricePlans || data.pricePlans.length === 0) return null;

  return (
    <>
      {data.pricePlans.map((plan, index) => (
        <CmsDynamicGridItem key={plan.id} variant={variant}>
          <PriceItem
            isIgnoreVisible={data.variant === 'cols_1' || !isTablet}
            data={plan}
            index={index}
            setItemProps={handleSetItemProps}
            heights={items[index] as PriceItemHeight}
          />
        </CmsDynamicGridItem>
      ))}
    </>
  );
};

export default PricingList;
