/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import React, { useState } from 'react';

import { css, cx } from '@pt-frontends/styled-system/css';
import { Flex, Grid, GridItem } from '@pt-frontends/styled-system/jsx';
import { cmsContentContactForm } from '@pt-frontends/styled-system-web/recipes';
import { CmsEditorText } from '@ui/cmsEditorText';
import { Icon } from '@ui/icon';
import { Img } from '@ui/img';
import { Text } from '@ui/text';

import { useIsVisible } from '@lib/hooks';
import { Cms_ContentContactForm } from '@lib/services/cms';
import { SmtpConfig } from '@lib/services/contact';

const ContactFormInner = React.lazy(() => import('./ContactFormInner'));
const ContactFormTermsDialog = React.lazy(() => import('./ContactFormTermsDialog'));

interface ContactFormProps {
  data: Cms_ContentContactForm;
  formId: string;
  hasCaptcha?: boolean;
  smtpConfig?: SmtpConfig;
  userEmail?: string;
  userCompany?: string;
  userName?: string;
}

const ContactForm: React.FC<ContactFormProps> = props => {
  const { ref, isVisible } = useIsVisible();
  const { data, formId } = props;
  const { containerInner, imgBox, infoIcon, infoText, gridItem, gridItemAddr } =
    cmsContentContactForm();
  const [isTermsOpen, setTermsOpen] = useState(false);
  const isTermsEnabled = data.terms && data.terms.checkboxLabel && data.terms.content;

  return (
    <>
      <Grid gap={8} columns={{ base: 1, lg: 2 }} className={containerInner}>
        <GridItem id={formId} className={cx(gridItem, css({ minH: 400 }))} ref={ref}>
          {isVisible && (
            <ContactFormInner {...props} isTermsOpen={isTermsOpen} setTermsOpen={setTermsOpen} />
          )}
        </GridItem>

        {data.address && (
          <GridItem className={gridItemAddr}>
            <div className={imgBox}>
              <Img fill data={data.address.image} alt={data.title} />
            </div>
            <Flex flexDir="column" gap={6} p={8}>
              <Flex gap={8}>
                <Icon i="pin-location" className={infoIcon} />
                <CmsEditorText data={data.address.address} variant="blockLg" className={infoText} />
              </Flex>
              <Flex gap={8}>
                <Icon i="phone" className={infoIcon} />
                <Text variant="block" className={infoText}>
                  <a href={`tel:${data.address.phone}`}>{data.address.phone}</a>
                </Text>
              </Flex>
              <Flex gap={8}>
                <Icon i="envelope" className={infoIcon} />
                <Text variant="block" className={infoText}>
                  <a href={`mailto:${data.address.email}`}>{data.address.email}</a>
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        )}
      </Grid>

      {isTermsEnabled && isTermsOpen && data.terms?.content && (
        <ContactFormTermsDialog
          data={data.terms?.content}
          isOpen={isTermsOpen}
          setOpen={setTermsOpen}
        />
      )}
    </>
  );
};

export { ContactForm };
export type { ContactFormProps };
