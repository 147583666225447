import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentTeamDefaultVariants = {}
const cmsContentTeamCompoundVariants = []

const cmsContentTeamSlotNames = [
  [
    "box",
    "cms-team__box"
  ],
  [
    "imageBox",
    "cms-team__imageBox"
  ],
  [
    "image",
    "cms-team__image"
  ],
  [
    "name",
    "cms-team__name"
  ],
  [
    "position",
    "cms-team__position"
  ],
  [
    "link",
    "cms-team__link"
  ],
  [
    "linkIcon",
    "cms-team__linkIcon"
  ]
]
const cmsContentTeamSlotFns = /* @__PURE__ */ cmsContentTeamSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentTeamDefaultVariants, getSlotCompoundVariant(cmsContentTeamCompoundVariants, slotName))])

const cmsContentTeamFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentTeamSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentTeamVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentTeamDefaultVariants, ...compact(variants) })

export const cmsContentTeam = /* @__PURE__ */ Object.assign(cmsContentTeamFn, {
  __recipe__: false,
  __name__: 'cmsContentTeam',
  raw: (props) => props,
  variantKeys: cmsContentTeamVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentTeamVariantKeys)
  },
  getVariantProps
})