import { Cms_EditorData, Cms_IconLink, Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export interface Cms_ContentEcosystemLayer {
  id: number;
  title: string;
  titleHtmlTag?: Cms_TitleTag;
  text?: Cms_EditorData;
  links?: Cms_IconLink[];
}

export interface Cms_ContentEcosystem extends Cms_ContentSlice {
  __component: 'content.ecosystem';
  businessLayer: Cms_ContentEcosystemLayer;
  automationLayer: Cms_ContentEcosystemLayer;
  infrastructureLayer: Cms_ContentEcosystemLayer;
}

export const populateEcosystem = {
  populate: {
    businessLayer: {
      populate: {
        links: populateLink
      }
    },
    automationLayer: {
      populate: {
        links: populateLink
      }
    },
    infrastructureLayer: {
      populate: {
        links: populateLink
      }
    },
    contentBlock: true
  }
};
