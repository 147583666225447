import { Cms_EditorData, Cms_Link, Cms_SingleMedia } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export type Cms_ContentCaseStudyVariant = 'image_text' | 'text_image';

export interface Cms_ContentCaseStudyTeaser extends Cms_ContentSlice {
  __component: 'content.case-study-teaser';
  title: string;
  text?: Cms_EditorData;
  image?: Cms_SingleMedia;
  imageZoom?: boolean;
  logo?: Cms_SingleMedia;
  link?: Cms_Link;
  variant: Cms_ContentCaseStudyVariant;
}

export const populateCaseStudyTeaser = {
  populate: {
    contentBlock: true,
    link: populateLink,
    image: true,
    logo: true
  }
};
