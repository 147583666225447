import { Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';
import { Cms_ContentTeasersSmallItem } from './teasersSmall';

export type Cms_ContentUspsVariant = 'cols_2' | 'cols_3';

export interface Cms_ContentUsps extends Cms_ContentSlice {
  __component: 'content.usp-s';
  title?: string;
  variant: Cms_ContentUspsVariant;
  usps: Cms_ContentTeasersSmallItem[];
  displayTitle?: string;
  titleHtmlTag?: Cms_TitleTag;
}

export const populateUsps = {
  populate: {
    usps: {
      populate: {
        link: populateLink
      }
    },
    contentBlock: true
  }
};
