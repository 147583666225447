import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentPricingDefaultVariants = {}
const cmsContentPricingCompoundVariants = []

const cmsContentPricingSlotNames = [
  [
    "title",
    "cms-pricing__title"
  ],
  [
    "item",
    "cms-pricing__item"
  ],
  [
    "itemBox",
    "cms-pricing__itemBox"
  ],
  [
    "itemTitle",
    "cms-pricing__itemTitle"
  ],
  [
    "services",
    "cms-pricing__services"
  ],
  [
    "priceLabel",
    "cms-pricing__priceLabel"
  ],
  [
    "price",
    "cms-pricing__price"
  ],
  [
    "service",
    "cms-pricing__service"
  ],
  [
    "cmsIcon",
    "cms-pricing__cmsIcon"
  ],
  [
    "text",
    "cms-pricing__text"
  ],
  [
    "imageWrap",
    "cms-pricing__imageWrap"
  ],
  [
    "img",
    "cms-pricing__img"
  ]
]
const cmsContentPricingSlotFns = /* @__PURE__ */ cmsContentPricingSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentPricingDefaultVariants, getSlotCompoundVariant(cmsContentPricingCompoundVariants, slotName))])

const cmsContentPricingFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentPricingSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentPricingVariantKeys = [
  "color",
  "pos"
]
const getVariantProps = (variants) => ({ ...cmsContentPricingDefaultVariants, ...compact(variants) })

export const cmsContentPricing = /* @__PURE__ */ Object.assign(cmsContentPricingFn, {
  __recipe__: false,
  __name__: 'cmsContentPricing',
  raw: (props) => props,
  variantKeys: cmsContentPricingVariantKeys,
  variantMap: {
  "color": [
    "white",
    "primary_light"
  ],
  "pos": [
    "title",
    "services",
    "button",
    "image",
    "price"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentPricingVariantKeys)
  },
  getVariantProps
})