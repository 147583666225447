'use client';

import * as React from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { accordion, borderBox } from '@pt-frontends/styled-system/recipes';
import * as AccodionRx from '@radix-ui/react-accordion';

import { Icon, IconType } from '@ui/icon';

const Accordion = AccodionRx.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccodionRx.Item>,
  React.ComponentPropsWithoutRef<typeof AccodionRx.Item>
>(({ className, ...props }, ref) => {
  const { item } = accordion();

  return <AccodionRx.Item ref={ref} className={cx(item, borderBox(), className)} {...props} />;
});
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccodionRx.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccodionRx.Trigger> & {
    title?: string | React.ReactNode;
    iconCl?: string;
    iconCollapse?: IconType;
    iconExpand?: IconType;
    collapseTitle?: string;
    titleCl?: string;
  }
>(
  (
    {
      className,
      children,
      title,
      iconCl,
      iconCollapse,
      iconExpand,
      collapseTitle,
      titleCl,
      ...props
    },
    ref
  ) => {
    const cl = accordion();

    return (
      <AccodionRx.Trigger ref={ref} className={cx('group', cl.trigger, className)} {...props}>
        {title ? (
          <div className={cx(cl.title, titleCl, collapseTitle && 'accordion-expand-title')}>
            {title}
          </div>
        ) : (
          children
        )}
        {collapseTitle ? (
          <div className={cx(cl.title, titleCl, 'accordion-collapse-title')}>{collapseTitle}</div>
        ) : (
          children
        )}
        <Icon
          className={cx(cl.toggleIcon, 'accordion-collapse-icon', iconCl)}
          i={iconCollapse ? iconCollapse : 'angles-collapse'}
        />
        <Icon
          className={cx(cl.toggleIcon, 'accordion-expand-icon', iconCl)}
          i={iconExpand ? iconExpand : 'angles-expand'}
        />
      </AccodionRx.Trigger>
    );
  }
);
AccordionTrigger.displayName = AccodionRx.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccodionRx.Content>,
  React.ComponentPropsWithoutRef<typeof AccodionRx.Content> & {
    clInner?: string;
  }
>(({ className, children, clInner, ...props }, ref) => {
  const cl = accordion();

  return (
    <AccodionRx.Content ref={ref} className={cx(cl.content, className)} {...props}>
      <div className={cx(cl.contentInner, clInner)}>{children}</div>
    </AccodionRx.Content>
  );
});
AccordionContent.displayName = AccodionRx.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
