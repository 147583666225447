import type { TFunction } from 'i18next';
import MeiliSearch from 'meilisearch';

import { PageData, PageProps } from '@lib/services/page';

export interface ServerStore {
  pageProps: PageProps;
  pageData: PageData;
  t: TFunction<any, any>;
  searchClient?: MeiliSearch;
}

const createStore = () => {
  let data: ServerStore = {} as ServerStore;

  const setPageProps = (props: PageProps) => {
    data.pageProps = props;
  };

  const getPageProps = () => {
    return data.pageProps;
  };

  const setPageData = (pageData: PageData) => {
    data.pageData = pageData;
  };

  const getPageData = () => {
    return data.pageData;
  };

  const setTranslation = (t: TFunction<any, any>) => {
    data.t = t;
  };

  const getTranslation = () => {
    return data.t;
  };

  const setSearchClient = (searchClient: MeiliSearch) => {
    data.searchClient = searchClient;
  };

  const getSearchClient = () => {
    return data.searchClient;
  };

  return {
    setPageProps,
    getPageProps,
    setPageData,
    getPageData,
    setTranslation,
    getTranslation,
    getSearchClient,
    setSearchClient
  };
};

export const store = createStore();
