'use client';

import React from 'react';

import { css } from '@pt-frontends/styled-system/css';
import { HStack, VStack } from '@pt-frontends/styled-system/jsx';
import { cmsContentDownloadForm } from '@pt-frontends/styled-system-web/recipes';
import { CmsIconClient } from '@ui/cmsIcon';
import { Icon } from '@ui/icon';
import { Img } from '@ui/img';
import { Text } from '@ui/text';

import { getTitleTag } from '@lib/utils';

import { DownloadFormProps } from './DownloadForm';

const listIcon = css({
  flexShrink: 0,
  color: 'primary'
});

const DownloadContentInner: React.FC<DownloadFormProps> = ({ data }) => {
  const { mainContainer, imageContainer, innerContainer, factsContainer, factsItem } =
    cmsContentDownloadForm();

  return (
    <div className={mainContainer}>
      {data.downloadImage && (
        <div className={imageContainer}>
          <Img fill data={data.downloadImage} alt={data.title} />
        </div>
      )}

      <div className={innerContainer}>
        <Text variant="h2" as={getTitleTag(data.downloadTitleHtmlTag, 'h2')}>
          {data.downloadTitle ?? data.title}
        </Text>

        {data.downloadFacts && data.downloadFacts.length > 0 && (
          <VStack gap={4} className={factsContainer}>
            {data.downloadFacts.map((fact, index) => (
              <HStack key={index} className={factsItem} alignItems="flex-start">
                {fact.icon ? (
                  <CmsIconClient i={fact.icon} className={listIcon} />
                ) : (
                  <Icon i="check-circle" className={listIcon} />
                )}
                <Text variant={'block-xs'} as="span">
                  {fact.title}
                </Text>
              </HStack>
            ))}
          </VStack>
        )}
      </div>
    </div>
  );
};

export default DownloadContentInner;
