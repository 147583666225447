import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentListDefaultVariants = {}
const cmsContentListCompoundVariants = []

const cmsContentListSlotNames = [
  [
    "container",
    "cms-list__container"
  ],
  [
    "icon",
    "cms-list__icon"
  ]
]
const cmsContentListSlotFns = /* @__PURE__ */ cmsContentListSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentListDefaultVariants, getSlotCompoundVariant(cmsContentListCompoundVariants, slotName))])

const cmsContentListFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentListSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentListVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentListDefaultVariants, ...compact(variants) })

export const cmsContentList = /* @__PURE__ */ Object.assign(cmsContentListFn, {
  __recipe__: false,
  __name__: 'cmsContentList',
  raw: (props) => props,
  variantKeys: cmsContentListVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentListVariantKeys)
  },
  getVariantProps
})