import React from 'react';

import { HtmlTagName } from '@lib/types';

import { DynamicTag } from '@ui/dynamicTag';

import type { CmsIconSvgNodeProps } from './CmsIcon.types';

const excludeAttributes = [
  'stroke-width',
  'stroke-linecap',
  'stroke-linejoin',
  'stroke-miterlimit'
];

const snakeToCamel = (s: string) => s.replace(/(_\w)/g, k => (k[1] as string).toUpperCase());

const CmsIconSvgNode: React.FC<CmsIconSvgNodeProps> = ({ data, className }) => {
  const { value, name, children, attributes } = data;
  const props: any = {};

  if (attributes) {
    Object.keys(attributes).forEach(key => {
      if (!excludeAttributes.includes(key)) {
        const camelKey = snakeToCamel(key);
        props[camelKey] = attributes[key];
      }
    });
  }

  if (name === 'defs' || name === 'title') {
    return null;
  }

  return (
    <DynamicTag tagName={name as HtmlTagName} {...props} className={className}>
      {value || null}
      {children && children.map((c, index) => <CmsIconSvgNode data={c} key={index} />)}
    </DynamicTag>
  );
};

export default CmsIconSvgNode;
