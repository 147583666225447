import React from 'react';

import { IntersectionOptions, useInView } from 'react-intersection-observer';

export const useIsVisible = (opt?: IntersectionOptions) => {
  const [isVisible, setVisible] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { ref, inView } = useInView(opt);

  React.useEffect(() => {
    if (inView) setVisible(inView);
  }, [inView]);

  return {
    ref,
    isVisible
  };
};
