import { useEffect, useState } from 'react';

import { useSearchParams } from 'next/navigation';

import { routes, CmsRouteParamType } from '@lib/services/routes';

export interface UseCmsParams {
  q?: string | null;
  params?: CmsRouteParamType;
}

export const useCmsParams = (blockId: number): UseCmsParams => {
  const q = useSearchParams()?.get('cms');
  const [params, setParams] = useState<CmsRouteParamType | undefined>();

  useEffect(() => {
    if (!q) {
      setParams(undefined);

      return;
    }

    setParams(routes.getCmsParams(q)?.[blockId.toString()]);
  }, [blockId, q]);

  return {
    q,
    params
  };
};
