import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentHeader5050DefaultVariants = {}
const cmsContentHeader5050CompoundVariants = []

const cmsContentHeader5050SlotNames = [
  [
    "container",
    "cms-header-5050__container"
  ],
  [
    "content",
    "cms-header-5050__content"
  ],
  [
    "contentInner",
    "cms-header-5050__contentInner"
  ],
  [
    "linksBox",
    "cms-header-5050__linksBox"
  ],
  [
    "linksBoxInner",
    "cms-header-5050__linksBoxInner"
  ],
  [
    "image",
    "cms-header-5050__image"
  ]
]
const cmsContentHeader5050SlotFns = /* @__PURE__ */ cmsContentHeader5050SlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentHeader5050DefaultVariants, getSlotCompoundVariant(cmsContentHeader5050CompoundVariants, slotName))])

const cmsContentHeader5050Fn = memo((props = {}) => {
  return Object.fromEntries(cmsContentHeader5050SlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentHeader5050VariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentHeader5050DefaultVariants, ...compact(variants) })

export const cmsContentHeader5050 = /* @__PURE__ */ Object.assign(cmsContentHeader5050Fn, {
  __recipe__: false,
  __name__: 'cmsContentHeader5050',
  raw: (props) => props,
  variantKeys: cmsContentHeader5050VariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentHeader5050VariantKeys)
  },
  getVariantProps
})