'use client';

import React from 'react';

import { css } from '@pt-frontends/styled-system/css';
import { GridItem } from '@pt-frontends/styled-system/jsx';
import { Carousel, CarouselContent, CarouselItem, CarouselPagination } from '@ui/carousel';
import { ImageViewer } from '@ui/imageViewer';
import dynamic from 'next/dynamic';

import { useIsMobile } from '@lib/hooks';

const EcosystemLastLayer = dynamic(() => import('./assets/EcosystemLastLayer'), { ssr: false });
const EcosystemMiddleLayer = dynamic(() => import('./assets/EcosystemMiddleLayer'), { ssr: false });
const EcosystemTopLayer = dynamic(() => import('./assets/EcosystemTopLayer'), {
  ssr: false
});

type ImageLayer = 1 | 2 | 3;

interface Props {
  setActiveLayer: React.Dispatch<React.SetStateAction<ImageLayer>>;
  activeLayer: ImageLayer;
}

const carousel = css({
  pos: 'relative',
  w: 'full',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pb: { base: 8, md: 0 }
});

const pagination = css({
  pos: 'absolute',
  bottom: -8
});

const detail = css({
  minW: 1000,
  minH: 1000,
  bg: 'white',
  p: 8
});

const trigger = css({
  top: 'calc(100% - 32px)',
  left: 'calc(100% - 20px)',
  bg: 'primary',
  color: 'white !important',
  opacity: '1 !important',
  '& svg path': {
    strokeWidth: 1.5
  }
});

const EcosystemMobileSlider: React.FC<Props> = ({ setActiveLayer }) => {
  const isMobile = useIsMobile();

  return (
    <GridItem colSpan={12} pos="relative">
      <Carousel
        className={carousel}
        opts={{ loop: true }}
        onIndexChange={i => {
          if (!isMobile) return;
          setActiveLayer((i + 1) as ImageLayer);
        }}
      >
        <CarouselContent className={css({ w: 'full' })}>
          <CarouselItem>
            <EcosystemTopLayer />
            <ImageViewer triggerCl={trigger}>
              <EcosystemTopLayer className={detail} />
            </ImageViewer>
          </CarouselItem>
          <CarouselItem>
            <EcosystemMiddleLayer />
            <ImageViewer triggerCl={trigger}>
              <EcosystemMiddleLayer className={detail} />
            </ImageViewer>
          </CarouselItem>
          <CarouselItem>
            <EcosystemLastLayer />
            <ImageViewer triggerCl={trigger}>
              <EcosystemLastLayer className={detail} />
            </ImageViewer>
          </CarouselItem>
        </CarouselContent>
        <CarouselPagination className={pagination} />
      </Carousel>
    </GridItem>
  );
};

export default EcosystemMobileSlider;
