'use client';

import React, { PropsWithChildren } from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { imageViewer } from '@pt-frontends/styled-system/recipes';

import { Cms_SingleMedia } from '@lib/services/cms';

import { Icon } from '@ui/icon';

const ImageViewerDialog = React.lazy(() => import('./ImageViewerDialog'));

export interface ImageViewerProps extends PropsWithChildren {
  image?: Cms_SingleMedia;
  triggerCl?: string;
}

const ImageViewer: React.FC<ImageViewerProps> = props => {
  const [isOpen, setOpen] = React.useState(false);
  const cl = imageViewer();
  const { triggerCl, ...rest } = props;

  return (
    <>
      <button
        className={cx(cl.trigger, triggerCl)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Icon i="search" className={cl.icon} />
      </button>

      {isOpen && <ImageViewerDialog {...rest} isOpen={isOpen} setOpen={setOpen} />}
    </>
  );
};

export { ImageViewer };
