import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const mainNavigationDefaultVariants = {}
const mainNavigationCompoundVariants = []

const mainNavigationSlotNames = [
  [
    "viewportPos",
    "main-navigation__viewportPos"
  ],
  [
    "viewPort",
    "main-navigation__viewPort"
  ]
]
const mainNavigationSlotFns = /* @__PURE__ */ mainNavigationSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, mainNavigationDefaultVariants, getSlotCompoundVariant(mainNavigationCompoundVariants, slotName))])

const mainNavigationFn = memo((props = {}) => {
  return Object.fromEntries(mainNavigationSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const mainNavigationVariantKeys = [
  "isBannerActive"
]
const getVariantProps = (variants) => ({ ...mainNavigationDefaultVariants, ...compact(variants) })

export const mainNavigation = /* @__PURE__ */ Object.assign(mainNavigationFn, {
  __recipe__: false,
  __name__: 'mainNavigation',
  raw: (props) => props,
  variantKeys: mainNavigationVariantKeys,
  variantMap: {
  "isBannerActive": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, mainNavigationVariantKeys)
  },
  getVariantProps
})