import { Cms_EditorData, Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

interface Cms_ContentTitleAccordionItem {
  id: number;
  title: string;
  titleHtmlTag: Cms_TitleTag;
  text: Cms_EditorData;
}

export interface Cms_ContentTitleAccordion extends Cms_ContentSlice {
  __component: 'content.title-and-accordion';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  items?: Cms_ContentTitleAccordionItem[];
}

export const populateTitleAccordion = {
  populate: {
    items: {
      populate: {}
    },
    contentBlock: true
  }
};
