'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { cookieBanner } from '@pt-frontends/styled-system-web/recipes';
import { keys } from 'lodash';
import { usePathname } from 'next/navigation';
import { useCookies } from 'react-cookie';

import { COOKIE_GA_CONSENT_KEY } from '@lib/config';
import { useIsMounted } from '@lib/hooks';
import { useTranslation } from '@lib/services/i18n/client';
import type { FetchStatus } from '@lib/types';
import { CookiePolicy } from '@lib/types';
import { addTrailingSlash, base64ToObject, objectToBase64 } from '@lib/utils';

const CookieSettingsDialog = React.lazy(() => import('./CookieSettingsDialog'));
const CookieSetupDialog = React.lazy(() => import('./CookieSetupDialog'));

interface CookieValues {
  date: string;
  nescessary: boolean;
  performance: boolean;
  marketing: boolean;
}

interface CookieBannerProps {
  privacyLink?: string;
  isSettings?: boolean;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ isSettings, privacyLink }) => {
  const [isSettingOpen, setSettingOpen] = useState(false);
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [isManageCookies, setIsManageCookies] = useState<boolean>(false);
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [cookies, setCookieValue, removeCookieValue] = useCookies();
  const cookieValue = cookies[COOKIE_GA_CONSENT_KEY];
  const [cookieValues, setCookieValues] = useState<CookiePolicy | null>(null);
  const { footerCookieBtn } = cookieBanner({
    isManagedCookies: isManageCookies || false
  });
  const pathname = usePathname();
  const isPrivacyPage = pathname === addTrailingSlash(privacyLink);
  const [formData, setFormData] = useState<Omit<CookiePolicy, 'date'>>();

  const setCookie = useCallback(
    (value: string) => {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      setCookieValue(COOKIE_GA_CONSENT_KEY, value, {
        expires: nextYear
      });
    },
    [setCookieValue]
  );

  const handleCookieSettings = () => {
    setIsManageCookies(true);
    setSettingOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeAnalyticsCookies = () => {
    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied'
      });
    }

    const hx = window.location.hostname.split('.');
    const domain = `.${hx[hx.length - 2]}.${hx[hx.length - 1]}`;

    keys(cookies).forEach(key => {
      if (key.startsWith('_')) {
        // eslint-disable-next-line no-console
        console.log('Removing ' + key);
        removeCookieValue(key, { domain, path: '/' });
        //  Cookies.remove(key, { domain, path: '/' });
      }
    });
  };

  const onSubmit = async () => {
    setStatus('loading');
    const cookieConf: CookiePolicy = {
      date: new Date().toISOString(),
      nescessary: true
    };
    if (!isManageCookies) {
      cookieConf.performance = true;
      cookieConf.marketing = true;
    } else {
      cookieConf.performance = (formData as CookieValues).performance;
      cookieConf.marketing = (formData as CookieValues).marketing;
    }
    if (!cookieConf.performance && !cookieConf.marketing) {
      removeAnalyticsCookies();
    }
    const cookieStr = objectToBase64(cookieConf);
    setCookie(cookieStr);
    setStatus('success');
    if (isSettings) {
      setSettingOpen(false);
    }
    window.location.reload();
  };

  useEffect(() => {
    if (cookieValues) return;

    let value: CookiePolicy | null = null;

    if (cookieValue) {
      try {
        const v = base64ToObject<CookiePolicy>(cookieValue as string);
        if (
          v &&
          v.marketing !== undefined &&
          v.performance !== undefined &&
          v.nescessary !== undefined
        ) {
          value = v;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Error parsing cookie, will remove...', e);
      }
    }

    if (!value && cookieValue) {
      // eslint-disable-next-line no-console
      console.warn('Error parsing cookie, will remove...');
      removeCookieValue(COOKIE_GA_CONSENT_KEY);
      removeAnalyticsCookies();
      window.location.reload();

      return;
    }

    if (!value) {
      return;
    }

    setCookieValues(value);
  }, [cookieValue, cookieValues, removeAnalyticsCookies, removeCookieValue]);

  /**
   * cleanup old cookie
   */
  useEffect(() => {
    if (cookies.DSG) {
      removeCookieValue('DSG');
    }
  }, [cookies?.DSG, removeCookieValue]);

  /**
   * temp cleanup of the old value
   */
  useEffect(() => {
    if (cookies.DSG) {
      removeCookieValue('DSG', { path: '/' });
    }
    if (cookies.KVANT_DSG) {
      removeCookieValue('KVANT_DSG', { path: '/' });
    }
  }, [cookies, removeCookieValue]);

  if (!isMounted) {
    return null;
  }

  if (isSettings) {
    // eslint-disable-next-line no-alert
    return (
      <>
        {isSettingOpen && (
          <CookieSettingsDialog
            isManageCookies={isManageCookies}
            isSettings={isSettings}
            cookieValues={cookieValues}
            setFormData={setFormData}
            formData={formData}
            setIsManageCookies={setIsManageCookies}
            setSettingOpen={setSettingOpen}
            onSubmit={onSubmit}
          />
        )}
        <button className={footerCookieBtn} onClick={handleCookieSettings}>
          {t('uiWeb.cookies.footer.label')}
        </button>
      </>
    );
  }

  if (cookieValues === null && !isPrivacyPage) {
    return (
      <CookieSetupDialog
        isPrivacyPage={isPrivacyPage}
        isSettings={isSettings || false}
        isManageCookies={isManageCookies}
        cookieValues={cookieValues}
        setFormData={setFormData}
        formData={formData}
        setIsManageCookies={setIsManageCookies}
        onSubmit={onSubmit}
        status={status}
      />
    );
  }

  return null;
};

export { CookieBanner };
export type { CookieBannerProps };
