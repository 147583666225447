export const BREAK_POINTS = {
  _: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1792,
  '4xl': 2048,
  '5xl': 2304,
  '6xl': 2560
};
