import { Cms_SingleMedia, Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

export interface Cms_ContentDownloadsDownload {
  id: number;
  title: string;
  file: Cms_SingleMedia;
}

export interface Cms_ContentDownloads extends Cms_ContentSlice {
  __component: 'content.downloads';
  title: string;
  titleHtmlTag?: Cms_TitleTag;
  downloads?: Cms_ContentDownloadsDownload[];
}

export const populateDownloads = {
  populate: {
    contentBlock: true,
    downloads: {
      populate: {
        file: true
      }
    }
  }
};
