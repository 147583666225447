import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentCTATilesDefaultVariants = {}
const cmsContentCTATilesCompoundVariants = []

const cmsContentCTATilesSlotNames = [
  [
    "container",
    "cms-cta-tiles__container"
  ],
  [
    "bgContainer",
    "cms-cta-tiles__bgContainer"
  ],
  [
    "bgOverlay",
    "cms-cta-tiles__bgOverlay"
  ],
  [
    "content",
    "cms-cta-tiles__content"
  ],
  [
    "contentInner",
    "cms-cta-tiles__contentInner"
  ],
  [
    "bgImg",
    "cms-cta-tiles__bgImg"
  ],
  [
    "teaserText",
    "cms-cta-tiles__teaserText"
  ],
  [
    "tagsBox",
    "cms-cta-tiles__tagsBox"
  ],
  [
    "tag",
    "cms-cta-tiles__tag"
  ],
  [
    "titleText",
    "cms-cta-tiles__titleText"
  ],
  [
    "titleBox",
    "cms-cta-tiles__titleBox"
  ],
  [
    "text",
    "cms-cta-tiles__text"
  ],
  [
    "linksWrap",
    "cms-cta-tiles__linksWrap"
  ]
]
const cmsContentCTATilesSlotFns = /* @__PURE__ */ cmsContentCTATilesSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentCTATilesDefaultVariants, getSlotCompoundVariant(cmsContentCTATilesCompoundVariants, slotName))])

const cmsContentCTATilesFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentCTATilesSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentCTATilesVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentCTATilesDefaultVariants, ...compact(variants) })

export const cmsContentCTATiles = /* @__PURE__ */ Object.assign(cmsContentCTATilesFn, {
  __recipe__: false,
  __name__: 'cmsContentCTATiles',
  raw: (props) => props,
  variantKeys: cmsContentCTATilesVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentCTATilesVariantKeys)
  },
  getVariantProps
})