import { Cms_EditorData, Cms_Link, Cms_TitleTag } from '../../../types';
import type { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export interface Cms_ContentTitleText extends Cms_ContentSlice {
  __component: 'content.title-and-text';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  teaser?: string;
  text?: Cms_EditorData;
  links?: Cms_Link[];
}

export const populateTitleText = {
  populate: {
    links: populateLink,
    contentBlock: true
  }
};
