import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentImageDefaultVariants = {}
const cmsContentImageCompoundVariants = []

const cmsContentImageSlotNames = [
  [
    "imageBox",
    "cms-image__imageBox"
  ],
  [
    "caption",
    "cms-image__caption"
  ],
  [
    "preserveImg",
    "cms-image__preserveImg"
  ]
]
const cmsContentImageSlotFns = /* @__PURE__ */ cmsContentImageSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentImageDefaultVariants, getSlotCompoundVariant(cmsContentImageCompoundVariants, slotName))])

const cmsContentImageFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentImageSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentImageVariantKeys = [
  "variant"
]
const getVariantProps = (variants) => ({ ...cmsContentImageDefaultVariants, ...compact(variants) })

export const cmsContentImage = /* @__PURE__ */ Object.assign(cmsContentImageFn, {
  __recipe__: false,
  __name__: 'cmsContentImage',
  raw: (props) => props,
  variantKeys: cmsContentImageVariantKeys,
  variantMap: {
  "variant": [
    "full_screen",
    "boxed",
    "small"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentImageVariantKeys)
  },
  getVariantProps
})