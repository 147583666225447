import { Cms_EditorData, Cms_Link, Cms_SingleMedia, Cms_TitleTag } from '../../../types';
import { Cms_ContentImageAspectRatio, Cms_ContentSlice } from '../pages.types';

export type Cms_ContentTextImageVariant = 'text_image' | 'image_text';

export interface Cms_ContentTextImage extends Cms_ContentSlice {
  __component: 'content.text-image';
  title: string;
  titleHtmlTag: Cms_TitleTag;
  text?: Cms_EditorData;
  variant: Cms_ContentTextImageVariant;
  image?: Cms_SingleMedia;
  imageZoom?: boolean;
  link?: Cms_Link;
  aspectRatio?: Cms_ContentImageAspectRatio;
}

export const populateTextImage = {
  populate: {
    contentBlock: true
  }
};
