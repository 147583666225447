import { Cms_EditorData } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

export interface Cms_ContentListItem {
  id: number;
  title: string;
  text?: Cms_EditorData;
}

export interface Cms_ContentList extends Cms_ContentSlice {
  __component: 'content.list';
  title: string;
  listItems: Cms_ContentListItem[];
  text?: Cms_EditorData;
}

export const populateList = {
  populate: {
    listItems: true,
    contentBlock: true
  }
};
