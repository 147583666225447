import { Cms_EditorData, Cms_Link, Cms_SingleMediaAttributes } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export type Cms_ContentCTAVariant = 'big_bg_image' | 'small' | 'small_shift_right';

export interface Cms_ContentCTA extends Cms_ContentSlice {
  __component: 'content.cta';
  title?: string;
  variant: Cms_ContentCTAVariant;
  teaser?: string;
  bgImage?: Cms_SingleMediaAttributes;
  text?: Cms_EditorData;
  links?: Cms_Link[];
}

export const populateCTA = {
  populate: {
    bgImage: true,
    contentBlock: true,
    links: populateLink
  }
};
