import { Cms_EditorData, Cms_SingleMedia } from '../../../types';
import { Cms_ContentImageAspectRatio, Cms_ContentSlice } from '../pages.types';

export type Cms_ContentImageVariant = 'full_screen' | 'boxed' | 'small';

export interface Cms_ContentImage extends Cms_ContentSlice {
  __component: 'content.image';
  image?: Cms_SingleMedia;
  imageZoom?: boolean;
  caption?: Cms_EditorData;
  title: string;
  variant: Cms_ContentImageVariant;
  aspectRatio?: Cms_ContentImageAspectRatio;
}

export const populateImage = {
  populate: {
    contentBlock: true,
    image: true
  }
};
