import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentVideoDefaultVariants = {}
const cmsContentVideoCompoundVariants = []

const cmsContentVideoSlotNames = [
  [
    "box",
    "cms-video__box"
  ],
  [
    "playerBox",
    "cms-video__playerBox"
  ],
  [
    "video",
    "cms-video__video"
  ],
  [
    "videoOverlay",
    "cms-video__videoOverlay"
  ],
  [
    "playIconBox",
    "cms-video__playIconBox"
  ],
  [
    "playBtnLabel",
    "cms-video__playBtnLabel"
  ],
  [
    "playBtnIcon",
    "cms-video__playBtnIcon"
  ]
]
const cmsContentVideoSlotFns = /* @__PURE__ */ cmsContentVideoSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentVideoDefaultVariants, getSlotCompoundVariant(cmsContentVideoCompoundVariants, slotName))])

const cmsContentVideoFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentVideoSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentVideoVariantKeys = [
  "isPlayerMounted"
]
const getVariantProps = (variants) => ({ ...cmsContentVideoDefaultVariants, ...compact(variants) })

export const cmsContentVideo = /* @__PURE__ */ Object.assign(cmsContentVideoFn, {
  __recipe__: false,
  __name__: 'cmsContentVideo',
  raw: (props) => props,
  variantKeys: cmsContentVideoVariantKeys,
  variantMap: {
  "isPlayerMounted": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentVideoVariantKeys)
  },
  getVariantProps
})