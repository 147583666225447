import { Cms_ExternalMedia, Cms_SingleMedia, Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

export interface Cms_ContentVideo extends Cms_ContentSlice {
  __component: 'content.video';
  title?: string;
  titleHtmlTag: Cms_TitleTag;
  video: Cms_ExternalMedia;
  previewImage?: Cms_SingleMedia;
  playButtonLabel?: string;
}

export const populateVideo = {
  populate: {
    contentBlock: true,
    previewImage: true
  }
};
