/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-named-default */
/**
 * https://ui.shadcn.com/docs/components/select
 * radix: https://www.radix-ui.com/primitives/docs/components/select#select
 */

'use client';

import * as React from 'react';

import {
  FormHelperText,
  MenuItemProps,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelectOrig
} from '@mui/material';
import type { SelectProps as MuiSelectPropsOrig } from '@mui/material/Select';
import { css, cx } from '@pt-frontends/styled-system/css';
import { select } from '@pt-frontends/styled-system/recipes';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';

import { useFormField } from '@ui/form';

interface SelectScrollUpButtonProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton> {}

interface SelectScrollDownButtonProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton> {}

interface SelectSeparatorProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> {}

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

interface SelectTriggerProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  noIcon?: boolean;
}

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ className, children, noIcon, ...props }, ref) => {
  const { trigger, triggerIcon, primitiveIcon } = select();

  return (
    <SelectPrimitive.Trigger ref={ref} className={cx('group', trigger, className)} {...props}>
      {children}
      {noIcon !== true && (
        <SelectPrimitive.Icon asChild className={triggerIcon}>
          <ChevronDown className={primitiveIcon} />
        </SelectPrimitive.Icon>
      )}
    </SelectPrimitive.Trigger>
  );
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  SelectScrollUpButtonProps
>(({ className, ...props }, ref) => {
  const { scrollUp } = select();

  return (
    <SelectPrimitive.ScrollUpButton ref={ref} className={cx(scrollUp, className)} {...props}>
      <ChevronUp className="h-4 w-4" />
    </SelectPrimitive.ScrollUpButton>
  );
});
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  SelectScrollDownButtonProps
>(({ className, ...props }, ref) => {
  const { scrollDown } = select();

  return (
    <SelectPrimitive.ScrollDownButton ref={ref} className={cx(scrollDown, className)} {...props}>
      <ChevronDown className="h-4 w-4" />
    </SelectPrimitive.ScrollDownButton>
  );
});
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

interface SelectContentProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> {}

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  SelectContentProps
>(({ className, children, position = 'popper', ...props }, ref) => {
  const { content, viewport } = select({ isPopover: position === 'popper' });

  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={cx(content, className)}
        position={position}
        {...props}
      >
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport className={cx(viewport)}>{children}</SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});
SelectContent.displayName = SelectPrimitive.Content.displayName;

interface SelectLabelProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label> {}

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  SelectLabelProps
>(({ className, ...props }, ref) => {
  const { label } = select();

  return <SelectPrimitive.Label ref={ref} className={cx(label, className)} {...props} />;
});
SelectLabel.displayName = SelectPrimitive.Label.displayName;

interface SelectItemProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> {
  showCheck?: boolean;
}

const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, SelectItemProps>(
  ({ className, children, ...props }, ref) => {
    const { item, itemSpan, itemIcon } = select();

    return (
      <SelectPrimitive.Item ref={ref} className={cx(item, 'group', className)} {...props}>
        <span className={itemSpan}>
          <SelectPrimitive.ItemIndicator>
            <Check className={itemIcon} />
          </SelectPrimitive.ItemIndicator>
        </span>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  }
);
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  SelectSeparatorProps
>(({ className, ...props }, ref) => {
  const { separator } = select();

  return <SelectPrimitive.Separator ref={ref} className={cx(separator, className)} {...props} />;
});
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

interface MuiSelectOption<T = any> {
  value: T;
  label: string;
}

const input = css({
  '& .check-icon': {
    display: 'none'
  }
});

type MuiSelectItemProps = MenuItemProps & {
  data?: MuiSelectOption;
};

const MuiSelectItem = React.forwardRef<HTMLLIElement, MuiSelectItemProps>((props, ref) => {
  const { children, value, data, ...rest } = props;

  return (
    <MuiMenuItem ref={ref} value={data?.value || value} {...rest}>
      {data?.label || children}
    </MuiMenuItem>
  );
});
MuiSelectItem.displayName = 'MuiSelectItem';

type MuiSelectProps = MuiSelectPropsOrig & {
  helperText?: string;
  label?: string;
  options?: MuiSelectOption[];
  checkIcon?: boolean;
};
const MuiSelect = React.forwardRef<HTMLDivElement, MuiSelectProps>((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const { error, children, label, value, disabled, helperText, options, checkIcon, ...restProps } =
    props;
  const { itemIcon } = select();

  let opt = children;
  if (options && options.length > 0) {
    opt = options.map(o => (
      <MuiMenuItem key={o.value} value={o.value} className="group">
        {checkIcon && <Check className={itemIcon} />}
        {o.label}
      </MuiMenuItem>
    ));
  }

  return (
    <MuiFormControl error={error} ref={ref}>
      {label && (
        <MuiInputLabel id="test" disabled={disabled} error={error}>
          {label}
        </MuiInputLabel>
      )}
      <MuiSelectOrig labelId="test" label={label} fullWidth className={input} {...restProps}>
        {opt}
      </MuiSelectOrig>
      {helperText && (
        <FormHelperText error={error} disabled={disabled}>
          {helperText}
        </FormHelperText>
      )}
    </MuiFormControl>
  );
});
MuiSelect.displayName = 'MuiSelect';

type MuiSelectInputProps = MuiSelectProps & {
  options?: MuiSelectOption[];
  checkIcon?: boolean;
  helperText?: React.ReactNode | string;
};
const MuiSelectInput = React.forwardRef<HTMLDivElement, MuiSelectInputProps>((props, ref) => {
  const { error } = useFormField();
  const { itemIcon } = select();
  // eslint-disable-next-line no-unused-vars
  const { options, children, label, value, checkIcon, helperText, disabled, ...restProps } = props;
  let opt = children;

  if (options && options.length > 0) {
    opt = options.map(o => (
      <MuiMenuItem key={o.value} value={o.value} className="group">
        {checkIcon && <Check className={itemIcon} />}
        {o.label}
      </MuiMenuItem>
    ));
  }

  return (
    <MuiFormControl error={error !== undefined} ref={ref}>
      {label && (
        <MuiInputLabel id="test" disabled={disabled} error={error !== undefined}>
          {label}
        </MuiInputLabel>
      )}
      <MuiSelectOrig labelId="test" label={label} fullWidth className={input} {...restProps}>
        {opt}
      </MuiSelectOrig>
      {helperText && (
        <FormHelperText error={error !== undefined} disabled={disabled}>
          {error?.message as any}
        </FormHelperText>
      )}
    </MuiFormControl>
  );
});
MuiSelectInput.displayName = 'MuiSelectInput';

export {
  MuiSelect,
  MuiSelectInput,
  MuiSelectItem,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue
};

export type {
  MuiSelectItemProps,
  MuiSelectOption,
  // Mui
  MuiSelectProps,
  SelectContentProps,
  SelectItemProps,
  SelectLabelProps,
  SelectScrollDownButtonProps,
  SelectScrollUpButtonProps,
  SelectSeparatorProps,
  SelectTriggerProps
};
