import { Cms_Color, Cms_Link, Cms_SingleMedia } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export interface Cms_ContentBrandsBannerItem {
  id: number;
  title?: string; // internal
  logo: Cms_SingleMedia;
  link?: Cms_Link;
}

export interface Cms_ContentBrandsBanner extends Cms_ContentSlice {
  __component: 'content.brands-banner';
  title?: string; // internal
  brands?: Cms_ContentBrandsBannerItem[];
  bgColor?: Cms_Color;
}

export const populateBrandsBanner = {
  populate: {
    contentBlock: true,
    brands: {
      populate: {
        logo: true,
        link: { ...populateLink }
      }
    }
  }
};
