import { Locale } from '../../i18n';
import type { Cms_EditorNodeData } from '../types';

export const API_DEFAULT_LOCALE: Locale = 'en';

export const API_DEFAULT_REVALIDATE = 15;

export const CMS_AUTH_HEADER = {
  Authorization: `Bearer ${process.env.CMS_API_TOKEN}`
};

export const CMS_DOCS_AUTH_HEADER = {
  Authorization: `Bearer ${process.env.CMS_DOCS_API_TOKEN}`
};

export const EMPTY_EDITOR_TEXT: Cms_EditorNodeData[] = [
  {
    type: 'p',
    children: [
      {
        text: ''
      }
    ]
  }
];
