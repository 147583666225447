import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentPartnersDefaultVariants = {}
const cmsContentPartnersCompoundVariants = []

const cmsContentPartnersSlotNames = [
  [
    "item",
    "cms-partners__item"
  ],
  [
    "tagList",
    "cms-partners__tagList"
  ],
  [
    "logo",
    "cms-partners__logo"
  ],
  [
    "logoInner",
    "cms-partners__logoInner"
  ],
  [
    "img",
    "cms-partners__img"
  ],
  [
    "itemTitle",
    "cms-partners__itemTitle"
  ],
  [
    "itemText",
    "cms-partners__itemText"
  ],
  [
    "itemLink",
    "cms-partners__itemLink"
  ]
]
const cmsContentPartnersSlotFns = /* @__PURE__ */ cmsContentPartnersSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentPartnersDefaultVariants, getSlotCompoundVariant(cmsContentPartnersCompoundVariants, slotName))])

const cmsContentPartnersFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentPartnersSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentPartnersVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentPartnersDefaultVariants, ...compact(variants) })

export const cmsContentPartners = /* @__PURE__ */ Object.assign(cmsContentPartnersFn, {
  __recipe__: false,
  __name__: 'cmsContentPartners',
  raw: (props) => props,
  variantKeys: cmsContentPartnersVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentPartnersVariantKeys)
  },
  getVariantProps
})