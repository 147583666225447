'use client';

import * as React from 'react';

import { css, cx } from '@pt-frontends/styled-system/css';
import { dialog } from '@pt-frontends/styled-system/recipes';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { Icon } from '@ui/icon';
import { ScrollArea } from '@ui/scrollArea';

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

// TYPES

interface DialogProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root> {
  onOpenChange?: (open: boolean) => void;
}

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  isCloseBtnShown?: boolean;
}

const Dialog: React.FC<DialogProps> = ({ ...props }) => {
  return <DialogPrimitive.Root {...props} />;
};

Dialog.displayName = DialogPrimitive.Root.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => {
  const { overlay } = dialog();

  return <DialogPrimitive.Overlay ref={ref} className={cx(overlay, className)} {...props} />;
});
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps & { overlayCl?: string }
>(({ className, children, isCloseBtnShown = true, overlayCl, ...props }, ref) => {
  const { content, closeBtn, closeIcon } = dialog();

  return (
    <DialogPortal>
      <DialogOverlay className={overlayCl} />
      <DialogPrimitive.Content ref={ref} className={cx(content, className)} {...props}>
        {children}
        {isCloseBtnShown && (
          <DialogPrimitive.Close className={cx(closeBtn, 'group')}>
            <Icon i="close" className={closeIcon} />
            <span className={css({ srOnly: true })}>Close</span>
          </DialogPrimitive.Close>
        )}
      </DialogPrimitive.Content>
    </DialogPortal>
  );
});
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => {
  const { title } = dialog();

  return <DialogPrimitive.Title ref={ref} className={cx(title, className)} {...props} />;
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { className, ...rest } = props;
    const { header } = dialog();

    return <div ref={ref} className={cx(header, className)} {...rest} />;
  }
);
DialogHeader.displayName = 'DialogHeader';

const DialogBody = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { scrollAreaCl?: string }
>((props, ref) => {
  const { className, children, scrollAreaCl, ...rest } = props;
  const { body, bodyInner } = dialog();

  return (
    <div ref={ref} className={cx(body, className)} {...rest}>
      <ScrollArea viewportCl={scrollAreaCl}>
        <div className={bodyInner}>{children}</div>
      </ScrollArea>
    </div>
  );
});
DialogBody.displayName = 'DialogBody';

const DialogTitleDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...rest }, ref) => {
  const { description } = dialog();

  return <DialogPrimitive.Description ref={ref} className={cx(description, className)} {...rest} />;
});
DialogTitleDescription.displayName = 'DialogTitleDescription';

const DialogFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { footer } = dialog();
    const { className, ...rest } = props;

    return <div ref={ref} className={cx(footer, className)} {...rest} />;
  }
);
DialogFooter.displayName = 'DialogFooter';

export {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTitleDescription,
  DialogTrigger
};

export type { DialogProps, DialogContentProps };
