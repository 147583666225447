import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentBrandsBannerDefaultVariants = {}
const cmsContentBrandsBannerCompoundVariants = []

const cmsContentBrandsBannerSlotNames = [
  [
    "box",
    "cms-brands-banner__box"
  ],
  [
    "imgWrap",
    "cms-brands-banner__imgWrap"
  ],
  [
    "list",
    "cms-brands-banner__list"
  ],
  [
    "listMobile",
    "cms-brands-banner__listMobile"
  ]
]
const cmsContentBrandsBannerSlotFns = /* @__PURE__ */ cmsContentBrandsBannerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentBrandsBannerDefaultVariants, getSlotCompoundVariant(cmsContentBrandsBannerCompoundVariants, slotName))])

const cmsContentBrandsBannerFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentBrandsBannerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentBrandsBannerVariantKeys = [
  "count"
]
const getVariantProps = (variants) => ({ ...cmsContentBrandsBannerDefaultVariants, ...compact(variants) })

export const cmsContentBrandsBanner = /* @__PURE__ */ Object.assign(cmsContentBrandsBannerFn, {
  __recipe__: false,
  __name__: 'cmsContentBrandsBanner',
  raw: (props) => props,
  variantKeys: cmsContentBrandsBannerVariantKeys,
  variantMap: {
  "count": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentBrandsBannerVariantKeys)
  },
  getVariantProps
})