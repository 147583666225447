import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentContactFormDefaultVariants = {}
const cmsContentContactFormCompoundVariants = []

const cmsContentContactFormSlotNames = [
  [
    "gridItem",
    "cms-contact-form__gridItem"
  ],
  [
    "gridItemAddr",
    "cms-contact-form__gridItemAddr"
  ],
  [
    "container",
    "cms-contact-form__container"
  ],
  [
    "containerInner",
    "cms-contact-form__containerInner"
  ],
  [
    "bgImage",
    "cms-contact-form__bgImage"
  ],
  [
    "teaser",
    "cms-contact-form__teaser"
  ],
  [
    "title",
    "cms-contact-form__title"
  ],
  [
    "formWrapper",
    "cms-contact-form__formWrapper"
  ],
  [
    "imgBox",
    "cms-contact-form__imgBox"
  ],
  [
    "infoIcon",
    "cms-contact-form__infoIcon"
  ],
  [
    "infoText",
    "cms-contact-form__infoText"
  ],
  [
    "resIcon",
    "cms-contact-form__resIcon"
  ],
  [
    "buttons",
    "cms-contact-form__buttons"
  ],
  [
    "resMsg",
    "cms-contact-form__resMsg"
  ],
  [
    "terms",
    "cms-contact-form__terms"
  ],
  [
    "termsCheckboxText",
    "cms-contact-form__termsCheckboxText"
  ],
  [
    "termsCheckboxError",
    "cms-contact-form__termsCheckboxError"
  ],
  [
    "termsDialogContent",
    "cms-contact-form__termsDialogContent"
  ]
]
const cmsContentContactFormSlotFns = /* @__PURE__ */ cmsContentContactFormSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentContactFormDefaultVariants, getSlotCompoundVariant(cmsContentContactFormCompoundVariants, slotName))])

const cmsContentContactFormFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentContactFormSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentContactFormVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentContactFormDefaultVariants, ...compact(variants) })

export const cmsContentContactForm = /* @__PURE__ */ Object.assign(cmsContentContactFormFn, {
  __recipe__: false,
  __name__: 'cmsContentContactForm',
  raw: (props) => props,
  variantKeys: cmsContentContactFormVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentContactFormVariantKeys)
  },
  getVariantProps
})