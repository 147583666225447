'use client';
import React, { useEffect } from 'react';

import { usePathname, useRouter } from 'next/navigation';

const hashRedirects = [
  ['h-virtual', '/en/product-and-services/kvant-cloud-platform/'],
  ['h-achieve', '/en/product-and-services/kvant-cloud-platform/'],
  ['hardware', '/en/product-and-services/kvant-cloud-platform/'],
  ['democratizing', '/en/product-and-services/kvant-cloud-platform/'],
  ['ready', '/en/product-and-services/kvant-cloud-platform/'],
  ['stories', '/en/resources/inside-phoenix/customer-stories/']
];

const ClientRedirects: React.FC = () => {
  const pathname = usePathname();
  const { replace } = useRouter();

  useEffect(() => {
    if (pathname !== '/en/') {
      return;
    }

    const hash = window.location.hash?.replace('#', '');
    if (!hash || hash === '') return;

    const match = hashRedirects.find(([key]) => key === hash);
    if (!match) return;

    replace(match[1] as string);
  }, [pathname, replace]);

  return <div />;
};

export default ClientRedirects;
