'use client';

import React from 'react';

import { css } from '@pt-frontends/styled-system/css';
import { cmsContentBrandsBanner } from '@pt-frontends/styled-system-web/recipes';
import { Carousel, CarouselContent, CarouselItem } from '@ui/carousel';
import { Img } from '@ui/img';
import Autoplay from 'embla-carousel-autoplay';

import { Cms_ContentBrandsBanner } from '@lib/services/cms';

interface BransBannerMobileProps {
  data: Cms_ContentBrandsBanner;
}

const mobileWrap = css({
  minH: '220px',
  pos: 'relative'
});

const mobileSlide = css({
  minH: '220px',
  w: 'calc(100vw - 112px)',
  mx: 10,
  pos: 'relative'
});

const BransBannerMobile: React.FC<BransBannerMobileProps> = ({ data }) => {
  const {} = cmsContentBrandsBanner();

  return (
    <div className={mobileWrap}>
      <Carousel opts={{ loop: true }} plugins={[Autoplay({ delay: 2000 })]}>
        <CarouselContent>
          {data.brands?.map((item, index) => (
            <CarouselItem index={index} key={data.id} className={css()}>
              <div className={mobileSlide}>
                <Img variant="contain" fill data={item.logo} alt={item.title} />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export { BransBannerMobile };
