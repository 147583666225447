import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentDownloadFormDefaultVariants = {}
const cmsContentDownloadFormCompoundVariants = []

const cmsContentDownloadFormSlotNames = [
  [
    "gridItem",
    "cms-download-form__gridItem"
  ],
  [
    "container",
    "cms-download-form__container"
  ],
  [
    "bgImage",
    "cms-download-form__bgImage"
  ],
  [
    "containerInner",
    "cms-download-form__containerInner"
  ],
  [
    "mainContainer",
    "cms-download-form__mainContainer"
  ],
  [
    "imageContainer",
    "cms-download-form__imageContainer"
  ],
  [
    "innerContainer",
    "cms-download-form__innerContainer"
  ],
  [
    "factsContainer",
    "cms-download-form__factsContainer"
  ],
  [
    "factsItem",
    "cms-download-form__factsItem"
  ],
  [
    "teaser",
    "cms-download-form__teaser"
  ],
  [
    "title",
    "cms-download-form__title"
  ],
  [
    "formWrapper",
    "cms-download-form__formWrapper"
  ],
  [
    "imgBox",
    "cms-download-form__imgBox"
  ],
  [
    "infoIcon",
    "cms-download-form__infoIcon"
  ],
  [
    "infoText",
    "cms-download-form__infoText"
  ],
  [
    "resIcon",
    "cms-download-form__resIcon"
  ],
  [
    "buttons",
    "cms-download-form__buttons"
  ],
  [
    "resMsg",
    "cms-download-form__resMsg"
  ],
  [
    "terms",
    "cms-download-form__terms"
  ],
  [
    "termsCheckboxText",
    "cms-download-form__termsCheckboxText"
  ],
  [
    "termsCheckboxError",
    "cms-download-form__termsCheckboxError"
  ],
  [
    "termsDialogContent",
    "cms-download-form__termsDialogContent"
  ]
]
const cmsContentDownloadFormSlotFns = /* @__PURE__ */ cmsContentDownloadFormSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentDownloadFormDefaultVariants, getSlotCompoundVariant(cmsContentDownloadFormCompoundVariants, slotName))])

const cmsContentDownloadFormFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentDownloadFormSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentDownloadFormVariantKeys = []
const getVariantProps = (variants) => ({ ...cmsContentDownloadFormDefaultVariants, ...compact(variants) })

export const cmsContentDownloadForm = /* @__PURE__ */ Object.assign(cmsContentDownloadFormFn, {
  __recipe__: false,
  __name__: 'cmsContentDownloadForm',
  raw: (props) => props,
  variantKeys: cmsContentDownloadFormVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, cmsContentDownloadFormVariantKeys)
  },
  getVariantProps
})