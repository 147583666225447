import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentTeasersSmallDefaultVariants = {}
const cmsContentTeasersSmallCompoundVariants = []

const cmsContentTeasersSmallSlotNames = [
  [
    "box",
    "cms-teaser-small__box"
  ],
  [
    "iconBox",
    "cms-teaser-small__iconBox"
  ],
  [
    "icon",
    "cms-teaser-small__icon"
  ],
  [
    "title",
    "cms-teaser-small__title"
  ],
  [
    "text",
    "cms-teaser-small__text"
  ]
]
const cmsContentTeasersSmallSlotFns = /* @__PURE__ */ cmsContentTeasersSmallSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentTeasersSmallDefaultVariants, getSlotCompoundVariant(cmsContentTeasersSmallCompoundVariants, slotName))])

const cmsContentTeasersSmallFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentTeasersSmallSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentTeasersSmallVariantKeys = [
  "bgColor"
]
const getVariantProps = (variants) => ({ ...cmsContentTeasersSmallDefaultVariants, ...compact(variants) })

export const cmsContentTeasersSmall = /* @__PURE__ */ Object.assign(cmsContentTeasersSmallFn, {
  __recipe__: false,
  __name__: 'cmsContentTeasersSmall',
  raw: (props) => props,
  variantKeys: cmsContentTeasersSmallVariantKeys,
  variantMap: {
  "bgColor": [
    "primary",
    "primary_dark"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentTeasersSmallVariantKeys)
  },
  getVariantProps
})