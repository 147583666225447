import { Cms_EditorData, Cms_TitleTag } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

export type Cms_ContentTableEditorLayout = 'FULL_WIDTH' | 'BOXED';

export type Cms_ContentTableEditorFontSize = 'DEFAULT' | 'SMALL';

export interface Cms_ContentTableEditor extends Cms_ContentSlice {
  __component: 'content.table-editor';
  text?: Cms_EditorData;
  title: string; // internal title
  displayTitle?: string; // title to display
  titleHtmlTag: Cms_TitleTag;
  minWidth?: number;
  layout: Cms_ContentTableEditorLayout;
  fontSize: Cms_ContentTableEditorFontSize;
}

export const populateTableEditor = {
  populate: {
    contentBlock: true
  }
};
