import { Cms_EditorData, Cms_Link } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

import { populateLink } from './populateCommon';

export type Cms_ContentTeasersSmallIconBgColor = 'primary' | 'primary_dark';

export interface Cms_ContentTeasersSmallItem {
  id: number;
  title: string;
  text: Cms_EditorData;
  link: Cms_Link;
  icon: string;
  iconBgColor: Cms_ContentTeasersSmallIconBgColor;
}

export interface Cms_ContentTeasersSmall extends Cms_ContentSlice {
  __component: 'content.teasers-small';
  title: string;
  teasers?: Cms_ContentTeasersSmallItem[];
}

export const populateTeasersSmall = {
  populate: {
    teasers: {
      populate: {
        link: populateLink
      }
    },
    contentBlock: true
  }
};
