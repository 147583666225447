'use client';

import React from 'react';

import { cmsContentList } from '@pt-frontends/styled-system-web/recipes';
import { CmsEditorText } from '@ui/cmsEditorText';
import { Icon } from '@ui/icon';

import type { Cms_ContentListItem } from '@lib/services/cms';

interface Props {
  data: Cms_ContentListItem;
}

const ContentListItem: React.FC<Props> = ({ data }) => {
  const { container, icon } = cmsContentList();

  return (
    <div className={container}>
      <Icon i={'check-circle'} className={icon} />

      <CmsEditorText variant="blockLg" data={data.text} />
    </div>
  );
};

export default ContentListItem;
