import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const mobileNavigationDefaultVariants = {}
const mobileNavigationCompoundVariants = []

const mobileNavigationSlotNames = [
  [
    "overlay",
    "mobile-navigation__overlay"
  ],
  [
    "content",
    "mobile-navigation__content"
  ],
  [
    "contentInner",
    "mobile-navigation__contentInner"
  ],
  [
    "contentSlider",
    "mobile-navigation__contentSlider"
  ],
  [
    "backWrap",
    "mobile-navigation__backWrap"
  ],
  [
    "backLabel",
    "mobile-navigation__backLabel"
  ],
  [
    "backIcon",
    "mobile-navigation__backIcon"
  ],
  [
    "navLink",
    "mobile-navigation__navLink"
  ],
  [
    "menuList",
    "mobile-navigation__menuList"
  ],
  [
    "menuListItem",
    "mobile-navigation__menuListItem"
  ],
  [
    "teaserBox",
    "mobile-navigation__teaserBox"
  ],
  [
    "navItem",
    "mobile-navigation__navItem"
  ],
  [
    "expandIcon",
    "mobile-navigation__expandIcon"
  ],
  [
    "navItemBtn",
    "mobile-navigation__navItemBtn"
  ],
  [
    "navItemLabel",
    "mobile-navigation__navItemLabel"
  ]
]
const mobileNavigationSlotFns = /* @__PURE__ */ mobileNavigationSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, mobileNavigationDefaultVariants, getSlotCompoundVariant(mobileNavigationCompoundVariants, slotName))])

const mobileNavigationFn = memo((props = {}) => {
  return Object.fromEntries(mobileNavigationSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const mobileNavigationVariantKeys = [
  "isBannerActive",
  "hasCurrentSubMenu",
  "isBackClicked",
  "active",
  "isTopLevel"
]
const getVariantProps = (variants) => ({ ...mobileNavigationDefaultVariants, ...compact(variants) })

export const mobileNavigation = /* @__PURE__ */ Object.assign(mobileNavigationFn, {
  __recipe__: false,
  __name__: 'mobileNavigation',
  raw: (props) => props,
  variantKeys: mobileNavigationVariantKeys,
  variantMap: {
  "isBannerActive": [
    "true"
  ],
  "hasCurrentSubMenu": [
    "true",
    "false"
  ],
  "isBackClicked": [
    "true"
  ],
  "active": [
    "true"
  ],
  "isTopLevel": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, mobileNavigationVariantKeys)
  },
  getVariantProps
})