import type { OpenGraphType } from 'next/dist/lib/metadata/types/opengraph-types';

export const META_OG_IMAGE_DEFAULTS = {
  width: 1240,
  height: 670,
  alt: 'og:img alt'
};

export const META_TWITTER_CARD_DEFAULT = 'summary_large_image';
export const META_OG_TYPE_DEFAULT: OpenGraphType = 'website';

export const META_ICON_SIZES = [16, 32, 96, 120, 180, 152, 167, 128, 196, 228];
export const META_APPLE_ICON_SIZES = [180, 120, 167, 152];
