import type { StaticRouteConfig } from '../types';

export const STATIC_ROUTES: StaticRouteConfig[] = [
  /**
   * DEVELOPMENT
   * -------------------------------------------------------------------
   */
  {
    pageId: 'development',
    route: {
      en: {
        slug: '_dev',
        path: '/_dev/'
      },
      de: {
        slug: '_dev',
        path: '/_dev/'
      }
    }
  },

  /**
   * BLOGPOSTS
   * -------------------------------------------------------------------
   */
  {
    pageId: 'blogPosts',
    catchBasePath: true,
    route: {
      en: {
        slug: 'news',
        path: '/resources/inside-phoenix/news/'
      },
      de: {
        slug: 'news',
        path: '/resources/phoenix-intern/news/'
      }
    }
  }

  /**
   * AUTH
   * -------------------------------------------------------------------
   */
  // {
  //   pageId: 'login',
  //   route: {
  //     en: {
  //       slug: 'login',
  //       path: '/login/'
  //     },
  //     de: {
  //       slug: 'login',
  //       path: '/login/'
  //     }
  //   }
  // }
];
