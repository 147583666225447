import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentCaseStudyTeaserDefaultVariants = {}
const cmsContentCaseStudyTeaserCompoundVariants = []

const cmsContentCaseStudyTeaserSlotNames = [
  [
    "container",
    "cms-case-study-teaser__container"
  ],
  [
    "imgBox",
    "cms-case-study-teaser__imgBox"
  ],
  [
    "img",
    "cms-case-study-teaser__img"
  ],
  [
    "contentBox",
    "cms-case-study-teaser__contentBox"
  ],
  [
    "content",
    "cms-case-study-teaser__content"
  ],
  [
    "logoBox",
    "cms-case-study-teaser__logoBox"
  ],
  [
    "textBox",
    "cms-case-study-teaser__textBox"
  ],
  [
    "linkBox",
    "cms-case-study-teaser__linkBox"
  ]
]
const cmsContentCaseStudyTeaserSlotFns = /* @__PURE__ */ cmsContentCaseStudyTeaserSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentCaseStudyTeaserDefaultVariants, getSlotCompoundVariant(cmsContentCaseStudyTeaserCompoundVariants, slotName))])

const cmsContentCaseStudyTeaserFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentCaseStudyTeaserSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentCaseStudyTeaserVariantKeys = [
  "variant"
]
const getVariantProps = (variants) => ({ ...cmsContentCaseStudyTeaserDefaultVariants, ...compact(variants) })

export const cmsContentCaseStudyTeaser = /* @__PURE__ */ Object.assign(cmsContentCaseStudyTeaserFn, {
  __recipe__: false,
  __name__: 'cmsContentCaseStudyTeaser',
  raw: (props) => props,
  variantKeys: cmsContentCaseStudyTeaserVariantKeys,
  variantMap: {
  "variant": [
    "image_text",
    "text_image"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentCaseStudyTeaserVariantKeys)
  },
  getVariantProps
})