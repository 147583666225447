import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const cmsContentHeaderBigDefaultVariants = {}
const cmsContentHeaderBigCompoundVariants = []

const cmsContentHeaderBigSlotNames = [
  [
    "container",
    "cms-header-big__container"
  ],
  [
    "imageContainer",
    "cms-header-big__imageContainer"
  ],
  [
    "contentOuter",
    "cms-header-big__contentOuter"
  ],
  [
    "content",
    "cms-header-big__content"
  ]
]
const cmsContentHeaderBigSlotFns = /* @__PURE__ */ cmsContentHeaderBigSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, cmsContentHeaderBigDefaultVariants, getSlotCompoundVariant(cmsContentHeaderBigCompoundVariants, slotName))])

const cmsContentHeaderBigFn = memo((props = {}) => {
  return Object.fromEntries(cmsContentHeaderBigSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const cmsContentHeaderBigVariantKeys = [
  "fullHeight"
]
const getVariantProps = (variants) => ({ ...cmsContentHeaderBigDefaultVariants, ...compact(variants) })

export const cmsContentHeaderBig = /* @__PURE__ */ Object.assign(cmsContentHeaderBigFn, {
  __recipe__: false,
  __name__: 'cmsContentHeaderBig',
  raw: (props) => props,
  variantKeys: cmsContentHeaderBigVariantKeys,
  variantMap: {
  "fullHeight": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, cmsContentHeaderBigVariantKeys)
  },
  getVariantProps
})