import { Cms_EditorData, Cms_SingleMedia } from '../../../types';
import { Cms_ContentSlice } from '../pages.types';

export interface Cms_ContentQuote extends Cms_ContentSlice {
  __component: 'content.quote';
  personName: string;
  personJobTitle?: string;
  personImage: Cms_SingleMedia;
  text?: Cms_EditorData;
}

export const populateQuote = {
  populate: {
    personImage: true,
    contentBlock: true
  }
};
